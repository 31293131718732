import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom-v5-compat';
import { useTranslation } from 'react-i18next';

import { Group, Tooltip } from '@good/ui/core';
import { Info } from '@good/icons';
import {
  bundledDatesRangeOptions,
  DateRangeFilter,
  type BundledOrCustomDatesRangeOption,
  type DatesRangeOption,
} from '@good/ui/templates';
import { type DatesRangeValue } from '@good/ui/dates';
import { ServiceFilter } from 'components/filters/service-filter';
import { ServiceDepartmentFilter } from 'components/filters/service-department-filter';
import { TeamMemberFilter } from 'components/filters/team-member-filter';
import { EmploymentTypeFilter } from 'components/filters/employment-type-filter';
import { usePayPeriodDatesRanges } from './use-pay-period-dates-ranges';

export type TimesheetsFiltersValue = {
  startDate: Date | null;
  endDate: Date | null;
  serviceIds: string[];
  serviceDepartmentIds: string[];
  teamMemberIds: string[];
  employmentTypes: string[];
};

type TimesheetsFiltersProps = {
  value: TimesheetsFiltersValue;
  onChange: (value: Partial<TimesheetsFiltersValue>) => void;
};

function isValidDatesRange(datesRange: DatesRangeValue): datesRange is [Date, Date] {
  return datesRange[0] instanceof Date && datesRange[1] instanceof Date;
}

export const TimesheetsFilters = ({ value, onChange }: TimesheetsFiltersProps) => {
  const { t } = useTranslation('', { keyPrefix: 'payments.timesheets.filters' });
  const [, setSearchParams] = useSearchParams();
  const [hasPayPeriod, setHasPayPeriod] = useState(true);
  const { startDate, endDate, serviceIds, serviceDepartmentIds, teamMemberIds, employmentTypes } = value;
  const defaultDateRange = bundledDatesRangeOptions['this-week'].range;
  const {
    currentPayPeriodRange,
    previousPayPeriodRange,
    isLoading: loadingPayPeriods,
  } = usePayPeriodDatesRanges((currentPayPeriodRange) => {
    if (!value.startDate || !value.endDate) {
      if (isValidDatesRange(currentPayPeriodRange)) {
        onChange({
          startDate: currentPayPeriodRange[0],
          endDate: currentPayPeriodRange[1],
        });
      } else {
        setHasPayPeriod(false);
        onChange({
          startDate: defaultDateRange[0],
          endDate: defaultDateRange[1],
        });
      }
    }
  });
  const payPeriodDatesRangeOptions: DatesRangeOption[] | undefined =
    isValidDatesRange(currentPayPeriodRange) && isValidDatesRange(previousPayPeriodRange)
      ? [
          {
            label: t('date.options.current'),
            range: currentPayPeriodRange,
          },
          {
            label: t('date.options.previous'),
            range: previousPayPeriodRange,
          },
        ]
      : undefined;
  const customDatesRangeOptions: BundledOrCustomDatesRangeOption[] = [
    'this-week',
    'last-week',
    'this-fortnight',
    'last-fortnight',
    'this-year',
    'last-year',
  ];
  const updateSearchParams = () => {
    setSearchParams((prev) => {
      const startDate_ = startDate?.toISOString() ?? prev.get('startDate');
      const endDate_ = endDate?.toISOString() ?? prev.get('endDate');
      const serviceIds_ = serviceIds ?? prev.getAll('serviceIds');
      const departmentIds_ = serviceDepartmentIds ?? prev.getAll('departmentIds');
      const teamMemberIds_ = teamMemberIds ?? prev.getAll('teamMemberIds');
      const employmentTypes_ = employmentTypes ?? prev.getAll('employmentTypes');
      if (!startDate_) {
        prev.delete('startDate');
      }
      if (!endDate_) {
        prev.delete('endDate');
      }
      return {
        ...(startDate_ ? { startDate: startDate_ } : {}),
        ...(endDate_ ? { endDate: endDate_ } : {}),
        departmentIds: departmentIds_,
        serviceIds: serviceIds_,
        teamMemberIds: teamMemberIds_,
        employmentTypes: employmentTypes_,
      };
    });
  };

  return (
    <Group>
      <DateRangeFilter
        value={[startDate, endDate]}
        onChange={([startDate, endDate]) =>
          onChange({
            startDate,
            endDate,
          })
        }
        initialValue={[startDate, endDate]}
        onClose={updateSearchParams}
        label={t('date.label')}
        rangeOptions={
          payPeriodDatesRangeOptions
            ? [
                ...payPeriodDatesRangeOptions,
                {
                  sectionLabel: t('date.options.custom'),
                  ranges: customDatesRangeOptions,
                },
              ]
            : customDatesRangeOptions
        }
        rangeOptionsTitle={
          <Group gap={6}>
            {t('date.title')}
            {!hasPayPeriod && (
              <Tooltip label={t('date.noPayPeriodConfigured')} position='top-start' color='lavender.9' withArrow>
                <Info />
              </Tooltip>
            )}
          </Group>
        }
        loading={loadingPayPeriods}
      />
      <TeamMemberFilter
        on='userId'
        value={teamMemberIds}
        onChange={(teamMemberIds) =>
          onChange({
            teamMemberIds,
          })
        }
        onClose={updateSearchParams}
      />
      <ServiceFilter
        value={serviceIds}
        onChange={(serviceIds) =>
          onChange({
            serviceIds,
          })
        }
        onClose={updateSearchParams}
      />
      <ServiceDepartmentFilter
        value={serviceDepartmentIds}
        onChange={(serviceDepartmentIds) =>
          onChange({
            serviceDepartmentIds,
          })
        }
        onClose={updateSearchParams}
      />

      <EmploymentTypeFilter
        value={employmentTypes}
        onChange={(employmentTypes) =>
          onChange({
            employmentTypes,
          })
        }
        onClose={updateSearchParams}
      />
    </Group>
  );
};
