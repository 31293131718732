import React, { PureComponent } from 'react';
import CommonUtils from '../../utilities/common-utils';
import TextTag from 'common-components/tags/TextTag';

interface IShiftSlotStatusTagProps {
  status: string;
  textSize?: string;
  className?: any;
  textClassName?: any;
  rounded?: boolean;
  size?: 'small' | 'default' | 'large';
  isFixedWidth?: boolean;
}

export default class ShiftSlotStatusTag extends PureComponent<IShiftSlotStatusTagProps> {
  StatusColors = {
    UNASSIGNED: { color: 'orange', theme: 'dark' },
    PENDING: { color: 'secondary', theme: 'dark' },
    CONFIRMED: { color: 'blue', theme: 'dark' },
    CHECKED_IN: { color: 'gold', theme: 'dark' },
    CHECKED_OUT: { color: 'green', theme: 'dark' },
    APPROVED: { color: 'blue', theme: 'dark' },
    NO_SHOW: { color: 'red', theme: 'dark' },
    SENT_TO_FINANCE: { color: 'blue', theme: 'light' },
    ON_LEAVE: { color: 'gray', theme: 'light' },
    LEAVE_APPROVED: { color: 'blue', theme: 'light' },
    CANCELLED_UNPAID: { color: 'red', theme: 'light' },
    CANCELLED_PAID: { color: 'red', theme: 'light' },
  };

  render() {
    const {
      status,
      textSize = 'regular',
      size = 'default',
      className = '',
      rounded = true,
      isFixedWidth = false,
    } = this.props;

    const color = this.StatusColors[status] ? this.StatusColors[status].color : 'blue';
    const theme = this.StatusColors[status] ? this.StatusColors[status].theme : 'dark';

    return (
      <TextTag
        theme={theme}
        color={color}
        textSize={textSize}
        size={size}
        className={className}
        content={CommonUtils.formatShiftSlotStatusString(status)}
        rounded={rounded}
        isFixedWidth={isFixedWidth}
      />
    );
  }
}
