import { IRosterBooking, IRosterShift } from './../common-components/roster-control/interfaces/roster-interfaces';
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import Utils from 'utilities/Utils';
import {
  AvailabilityRequestType,
  BookingType,
  FilterLabel,
  FilterType,
  MmmGroup,
  PriceFrenquency,
  ServiceType,
  ShiftSlotStatus,
} from 'utilities/enum-utils';
import { GOOGLE_MAPS } from '../config/app-config';
import { IShiftClashConflict } from 'interfaces/service-interfaces';
import { ndisHelper } from 'variables/data-helpers';

type DurationFormat = { value: number; label: string };

type DurationFormatter = {
  years?: DurationFormat;
  months?: DurationFormat;
  days?: DurationFormat;
  hours?: DurationFormat;
  minutes?: DurationFormat;
};

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export class CommonUtils {
  static formatStatusString(status: string): string {
    switch (status) {
      case 'PENDING':
        return 'Pending';
      case 'PENDING_APPROVAL':
        return 'Pending Approval';
      case 'REQUEST_CANCELLATION':
        return 'Cancellation Request';
      case 'ACCEPTED':
        return 'Accepted';
      case 'CONFIRMED':
        return 'Confirmed';
      case 'INPROGRESS':
        return 'In Progress';
      case 'COMPLETED':
        return 'Completed';
      case 'APPROVED':
        return 'Approved';
      case 'CLOSED':
        return 'Achieved';
      case 'CANCELLED':
        return 'Cancelled';
      case 'REJECTED':
        return 'Rejected';
      case 'READY_FOR_BILLING':
        return 'Ready for billing';
      case 'REQUIRES_APPROVAL':
        return 'Requires Approval';
      case 'BILLING_FAILED':
        return 'Finance removed';
      case 'BILLED':
        return 'Billed';
      case 'CUSTOMER_CANCELLED':
        return 'Customer Cancelled';
      case 'CUSTOMER_CANCELLED_WITH_FEE':
        return 'Cancelled - Fee';
      case 'CUSTOMER_CANCELLED_WITHOUT_FEE':
        return 'Cancelled - No fee';
      case 'BUSINESS_CANCELLED':
        return 'Business Cancelled';
      case 'ARCHIVED':
        return 'Archived';
      case 'UNASSIGNED':
        return 'Unassigned';
      case 'CHECKED_IN':
        return 'Checked in';
      case 'CHECKED_OUT':
        return 'Checked out';
      case 'PUBLISHING':
        return 'Publishing';
      case 'PUBLISHED':
        return 'Published';
      case 'CLAIMED':
        return 'Claimed';
      case 'NO_SHOW':
        return 'No show';
      case 'DECLINED':
        return 'Declined';
      case 'SCHEDULED':
        return 'Scheduled';
      case 'SEND_TO_FINANCE':
        return 'Sent to finance';
      case 'ON_LEAVE':
        return 'On leave';
      case 'LEAVE_APPROVED':
        return 'Leave approved';
      case 'CANCELLED_UNPAID':
        return 'Cancelled - Unpaid';
      case 'CANCELLED_PAID':
        return 'Cancelled - Paid';
      default:
        return 'N/A';
    }
  }

  static formatTooltipStatusString(status: string): string {
    switch (status) {
      case 'CUSTOMER_CANCELLED_WITH_FEE':
        return 'Customer Cancelled - Fee';
      case 'CUSTOMER_CANCELLED_WITHOUT_FEE':
        return 'Customer Cancelled - No fee';
      default:
        return '';
    }
  }

  static formatServiceStatusString(status: string): string {
    switch (status) {
      case 'DRAFT':
        return 'Draft';
      case 'PUBLISHED':
        return 'Published';
      case 'UNPUBLISHED':
        return 'Unpublished';
      case 'ARCHIVED':
        return 'Archived';
      default:
        return 'N/A';
    }
  }

  static formatFormStatusString(status: string): string {
    switch (status) {
      case 'DRAFT':
        return 'Draft';
      case 'ACTIVE':
        return 'Active';
      case 'INUSE':
        return 'Inuse';
      case 'ARCHIVED':
        return 'Archived';
      default:
        return 'N/A';
    }
  }

  static formatWorkflowStatus(status: string): string {
    switch (status) {
      case 'ACTIVE':
        return 'Active';
      case 'INACTIVE':
        return 'Inactive';
      default:
        return 'N/A';
    }
  }

  static formatSessionStatusString(status: string): string {
    switch (status) {
      case 'SCHEDULED':
        return 'Scheduled';
      case 'INPROGRESS':
        return 'In Progress';
      case 'COMPLETED':
        return 'Completed';
      case 'CLOSED':
        return 'Closed';
      case 'CANCELLED':
        return 'Cancelled';
      default:
        return 'N/A';
    }
  }

  static formatShiftSlotStatusString(status: ShiftSlotStatus | string): string {
    switch (status) {
      case ShiftSlotStatus.UNASSIGNED:
        return 'Unassigned';
      case ShiftSlotStatus.PENDING:
        return 'Pending';
      case ShiftSlotStatus.CONFIRMED:
        return 'Confirmed';
      case ShiftSlotStatus.CHECKED_IN:
        return 'Checked in';
      case ShiftSlotStatus.CHECKED_OUT:
        return 'Checked out';
      case ShiftSlotStatus.APPROVED:
        return 'Approved';
      case ShiftSlotStatus.NO_SHOW:
        return 'No show';
      case ShiftSlotStatus.SENT_TO_FINANCE:
        return 'Sent to finance';
      case ShiftSlotStatus.ON_LEAVE:
        return 'On leave';
      case ShiftSlotStatus.LEAVE_APPROVED:
        return 'Leave approved';
      case ShiftSlotStatus.PUBLISHED:
        return 'Published';
      case ShiftSlotStatus.CANCELLED_UNPAID:
        return 'Unpaid - Cancelled';
      case ShiftSlotStatus.CANCELLED_PAID:
        return 'Paid - Cancelled';
      default:
        return 'N/A';
    }
  }

  static mapWorkerStatus(status: string): string {
    switch (status) {
      case 'FINDING':
        return 'Finding Worker';
      case 'REJECTED':
        return 'Worker Rejected';
      case 'FOUND':
        return 'Worker Confirmed';
      default:
        return 'N/A';
    }
  }

  static cancelReasons = {
    C1: 'C1',
    C2: 'C2',
    C3: 'C3',
    C4: 'C4',
  };

  static mapCancelReason(status: string): string {
    return CommonUtils.cancelReasons[status];
  }

  static formatPrice(price: number): string {
    // return price ? parseFloat(price.toString()).toFixed(2) : '0.00';
    return price ? formatter.format(price) : '$0.00';
  }

  static formatDuration(startDateTime: Moment, endDateTime: Moment) {
    const duration = moment.duration(endDateTime.diff(startDateTime));

    const durationFormatter: DurationFormatter = {};

    const years = duration.years();
    const months = duration.months();
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    if (years > 0) {
      durationFormatter.years = {
        value: years,
        label: years > 1 ? 'years' : 'year',
      };
    }

    if (months > 0) {
      durationFormatter.months = {
        value: months,
        label: months > 1 ? 'months' : 'month',
      };
    }

    if (days > 0) {
      durationFormatter.days = {
        value: days,
        label: days > 1 ? 'days' : 'day',
      };
    }

    if (hours > 0) {
      durationFormatter.hours = {
        value: hours,
        label: hours > 1 ? 'hours' : 'hour',
      };
    }

    if (minutes > 0) {
      durationFormatter.minutes = {
        value: minutes,
        label: minutes > 1 ? 'minutes' : 'minute',
      };
    }

    return durationFormatter;
  }

  static formatDurationFromSeconds(durationInSeconds: number) {
    const duration = moment.duration(durationInSeconds, 'seconds');

    const durationFormatter: DurationFormatter = {};

    if (duration.years() > 0) {
      durationFormatter.years = {
        value: duration.years(),
        label: 'year(s)',
      };
    }

    if (duration.months() > 0) {
      durationFormatter.months = {
        value: duration.months(),
        label: 'month(s)',
      };
    }

    if (duration.days() > 0) {
      durationFormatter.days = {
        value: duration.days(),
        label: 'day(s)',
      };
    }

    if (duration.hours() > 0) {
      durationFormatter.hours = {
        value: duration.hours(),
        label: 'hour(s)',
      };
    }

    if (duration.minutes() > 0) {
      durationFormatter.minutes = {
        value: duration.minutes(),
        label: 'minute(s)',
      };
    }
    return durationFormatter;
  }

  // Renders the duration object as a string, eg 5 years 2 months 5 hours 2 minutes
  static formatDurationString(momentStartTime: Moment, momentEndTime: Moment) {
    const duration = this.formatDuration(momentStartTime, momentEndTime);

    return _.chain(duration)
      .keys()
      .map((key) => `${duration[key].value} ${duration[key].label}`)
      .join(' ')
      .value();
  }

  // Renders the duration object as a string, eg 5 years 2 months 5 hours 2 minutes
  static formatDurationFromSecondsString(durationFromSeconds: number) {
    const duration = this.formatDurationFromSeconds(durationFromSeconds);

    return _.chain(duration)
      .keys()
      .map((key) => `${duration[key].value} ${duration[key].label}`)
      .join(' ')
      .value();
  }

  static formatHoursFromSeconds(seconds: number) {
    const totalHours = seconds / 3600;
    const roundedTotalHours = Math.round(totalHours * 10) / 10;

    return `${roundedTotalHours} hours`;
  }

  // Address/location Utils
  static formatFullAddress({
    streetAddress1,
    streetAddress2,
    locality,
    state,
    postcode,
    country,
  }: {
    streetAddress1: string;
    streetAddress2: string;
    locality: string;
    state: string;
    postcode: string;
    country: string;
  }) {
    const streetAddress = `${streetAddress1 ? streetAddress1 : ''}${streetAddress2 ? ' ' + streetAddress2 : ''}`;
    const suburbStatePostcode = `${locality ? locality + ' ' : ''}${state ? state + ' ' : ''}${
      postcode ? postcode : ''
    }`;
    // ! format full address by adding space and comma when data are present
    return `${streetAddress ? streetAddress + ',\n' : ''}${suburbStatePostcode ? suburbStatePostcode + ',\n' : ''}${
      country ? country : ''
    }`;
  }

  static getLatLongByAddress = async (fulladdress) => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_MAPS.API_KEY}&address=${fulladdress}`,
    );
    const responseJson = await response.json();

    return responseJson.results[0].geometry.location;
  };

  static formatCeilingDateTime(date, duration = moment.duration(5, 'minutes')) {
    return moment(Math['ceil'](+date / +duration) * +duration);
  }

  static teamMemberPermissionTag(ownerRole: number, hasPortalAccess?: boolean, hasWorkerAppAccess?: boolean) {
    let tag = '';
    if (ownerRole === 1) {
      tag = 'Primary Owner';
    } else if (ownerRole === 2) {
      tag = 'Owner';
    } else {
      if (hasPortalAccess) {
        tag = 'Workspace';
      }
      if (hasWorkerAppAccess) {
        tag += tag !== '' ? ', Business app' : 'Business app';
      }
    }

    return tag;
  }

  static getFilterSettings(filterType) {
    let title = '';
    let searchPlaceHolder = '';
    let canSearch = false;
    let itemSingularName = '';
    let itemPluralName = '';
    let fullSelectionName = '';
    let emptyName = '';
    let customTitle = '';

    if (filterType === FilterType.CUSTOMER) {
      title = 'Customer';
      searchPlaceHolder = 'Search for customer...';
      canSearch = true;
      itemSingularName = ' customer';
      itemPluralName = ' customers';
      fullSelectionName = 'Everyone';
    } else if (filterType === FilterType.DATE_RANGE) {
      title = 'Date';
      canSearch = false;
      fullSelectionName = 'All';
    } else if (filterType === FilterType.WORKER) {
      title = 'Team member';
      searchPlaceHolder = 'Search for team member...';
      canSearch = true;
      itemSingularName = ' team member';
      itemPluralName = ' team members';
      fullSelectionName = 'Everyone';
    } else if (filterType === FilterType.SERVICE) {
      title = 'Service';
      searchPlaceHolder = 'Search for service...';
      canSearch = true;
      itemSingularName = ' service';
      itemPluralName = ' services';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.BOOKING_STATUS) {
      title = 'Status';
      searchPlaceHolder = 'Search for status...';
      canSearch = true;
      itemSingularName = ' status';
      itemPluralName = ' status';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.SHIFT_SLOT_STATUS) {
      title = 'Team member status';
      searchPlaceHolder = 'Search for status...';
      canSearch = true;
      itemSingularName = ' status';
      itemPluralName = ' status';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.RECURRING) {
      title = 'Is recurring booking';
      fullSelectionName = 'Both';
    } else if (filterType === FilterType.PINNED_ALERTS) {
      title = 'Show customer with pinned alerts';
      fullSelectionName = 'Both';
    } else if (filterType === FilterType.SHIFT_SLOT_WARNINGS) {
      title = 'Only slots with warnings';
      emptyName = 'No';
      fullSelectionName = 'Yes';
    } else if (filterType === FilterType.PAYMENT_STATUS) {
      title = 'Payment status';
      searchPlaceHolder = 'Search for status...';
      canSearch = true;
      itemSingularName = ' status';
      itemPluralName = ' status';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.PAYMENT_WARNING) {
      title = 'Warnings';
      searchPlaceHolder = 'Search for warnings...';
      canSearch = true;
      itemSingularName = ' warning';
      itemPluralName = ' warnings';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.IS_INCIDENT) {
      title = 'Incident';
      canSearch = false;
      fullSelectionName = 'Both';
    } else if (filterType === FilterType.SHOW_ARCHIVED_BOOKINGS) {
      title = 'Show archived bookings';
      canSearch = false;
      fullSelectionName = 'Both';
    } else if (filterType === FilterType.NOTE_TYPE) {
      title = 'Note type';
      canSearch = false;
      fullSelectionName = 'All';
    } else if (filterType === FilterType.NOTE_AUTHOR || filterType === FilterType.BATCH_AUTHOR) {
      title = 'Batch author';
      searchPlaceHolder = 'Search for batch author...';
      canSearch = true;
      itemSingularName = ' author';
      itemPluralName = ' authors';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.NOTE_SERVICE) {
      title = 'Service';
      searchPlaceHolder = 'Search for service...';
      canSearch = true;
      itemSingularName = ' service';
      itemPluralName = ' services';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.SERVICE_TYPE) {
      title = 'Service type';
      canSearch = false;
      itemSingularName = 'service type';
      itemPluralName = 'service types';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.NDIS_CATEGORIES) {
      title = 'Support categories';
      searchPlaceHolder = 'Search for a category...';
      canSearch = true;
      itemSingularName = ' category';
      itemPluralName = ' categories';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.SERVICE_STATUS) {
      title = 'Service status';
      itemSingularName = ' status';
      itemPluralName = ' status';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.SERVICE_DEPARTMENT) {
      title = 'Department';
      itemSingularName = ' department';
      itemPluralName = ' departments';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.PROVIDER) {
      title = 'Provider';
      searchPlaceHolder = 'Search for provider...';
      canSearch = true;
      itemSingularName = ' provider';
      itemPluralName = ' providers';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.SUPPLIER_PAYMENT_STATUS) {
      title = 'Provider payment status';
      itemSingularName = ' status';
      itemPluralName = ' status';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.APPROVAL_STATUS) {
      title = 'Approval status';
      itemSingularName = ' status';
      itemPluralName = ' status';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.CLAIM_STATUS) {
      title = 'Claim status';
      itemSingularName = ' status';
      searchPlaceHolder = 'Search for claim status...';
      canSearch = true;
      itemPluralName = ' status';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.BOOKING_ERROR_TYPES) {
      title = 'Warnings/Errors';
      searchPlaceHolder = 'Search for a error...';
      itemPluralName = ' warnings';
      itemSingularName = ' warning';
      fullSelectionName = 'All';
      canSearch = false;
    } else if (filterType === FilterType.QUALIFICATIONS) {
      title = 'Qualifications';
      itemSingularName = ' qualification';
      itemPluralName = ' qualifications';
      searchPlaceHolder = 'Search for qualification...';
      canSearch = true;
      fullSelectionName = 'All';
    } else if (filterType === FilterType.SPECIALITIES) {
      title = 'Specialities';
      itemSingularName = ' speciality';
      itemPluralName = ' specialities';
      searchPlaceHolder = 'Search for speciality...';
      canSearch = true;
      fullSelectionName = 'All';
    } else if (filterType === FilterType.RELIGIONS) {
      title = 'Religions';
      itemSingularName = ' religion';
      itemPluralName = ' religions';
      searchPlaceHolder = 'Search for religion...';
      canSearch = true;
      fullSelectionName = 'All';
    } else if (filterType === FilterType.GENDER) {
      title = 'Gender';
      itemSingularName = ' gender';
      itemPluralName = ' genders';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.SKILLS) {
      title = 'Skills';
      itemSingularName = ' skill';
      itemPluralName = ' skills';
      searchPlaceHolder = 'Search for skill...';
      canSearch = true;
      fullSelectionName = 'All';
    } else if (filterType === FilterType.LANGUAGES) {
      title = 'Languages Spoken';
      itemSingularName = ' language';
      itemPluralName = ' languages';
      searchPlaceHolder = 'Search for language...';
      canSearch = true;
      fullSelectionName = 'All';
    } else if (filterType === FilterType.INTEREST) {
      title = 'Interests';
      itemSingularName = ' interest';
      itemPluralName = ' interest';
      searchPlaceHolder = 'Search for interest...';
      canSearch = true;
    } else if (filterType === FilterType.CUSTOM_CUSTOMER) {
      title = 'Customers';
      itemSingularName = ' customer';
      itemPluralName = ' customers';
      searchPlaceHolder = 'Search for customer...';
      canSearch = true;
      fullSelectionName = 'All';
    } else if (filterType === FilterType.PAYMENT_METHODS) {
      title = 'Payment method';
      itemSingularName = ' payment method';
      itemPluralName = ' payment methods';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.FUNDING_SOURCES) {
      title = 'Funding method';
      itemSingularName = ' funding method';
      itemPluralName = ' funding methods';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.SESSION_STATUS) {
      title = 'Status';
      itemSingularName = ' status';
      itemPluralName = ' status';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.SESSION_STAFFING_STATUS) {
      title = 'Staffing status';
      itemSingularName = ' staffing status';
      itemPluralName = ' staffing status';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.CUSTOMER_CONNECTION_STATUS) {
      title = 'Connection status';
      itemSingularName = ' connection status';
      itemPluralName = ' connection status';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.PERMANENT_CONDITIONS) {
      title = 'Permanent Conditions';
      itemSingularName = ' permanent conditions';
      itemPluralName = ' permanent conditions';
      searchPlaceHolder = 'Search for a condition...';
      fullSelectionName = 'All';
      canSearch = true;
    } else if (filterType === FilterType.CUSTOMER_ALERTS) {
      title = 'Alerts';
      itemSingularName = ' Alert';
      itemPluralName = ' Alerts';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.USER_LOCATION_BY_STATE) {
      title = 'Location';
      itemSingularName = ' location';
      itemPluralName = ' locations';
      fullSelectionName = 'All';
      canSearch = true;
      searchPlaceHolder = 'Search by state...';
    } else if (filterType === FilterType.CUSTOMER_STATUS) {
      title = 'Customer status';
      itemSingularName = 'customer status';
      itemPluralName = 'customer status';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.MANAGEMENT_METHOD) {
      title = 'Manangement method';
      itemSingularName = ' manangement method';
      itemPluralName = ' management method';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.CUSTOMER_MANAGED_BY) {
      title = 'Managed By';
      itemSingularName = ' managed by';
      itemPluralName = ' managed by';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.PREFERRED_SUPPORT_WORKER) {
      title = 'Preferred team member';
      searchPlaceHolder = 'Search for team member...';
      canSearch = true;
      itemSingularName = ' preferred team member';
      itemPluralName = ' preferred team members';
      fullSelectionName = 'Everyone';
    } else if (filterType === FilterType.BLOCKED_SUPPORT_WORKER) {
      title = 'Blocked team member';
      searchPlaceHolder = 'Search for team member...';
      canSearch = true;
      itemSingularName = ' blocked team member';
      itemPluralName = ' blocked team members';
      fullSelectionName = 'Everyone';
    } else if (filterType === FilterType.BOOKING_TYPES) {
      title = 'Booking type';
    } else if (filterType === FilterType.WORKER_ALERTS) {
      title = 'Alerts';
    } else if (filterType === FilterType.DATE_PAID) {
      title = 'Date paid';
    } else if (filterType === FilterType.INVOICE_DATE) {
      title = 'Invoice date';
    } else if (filterType === FilterType.SERVICE_DATE_TIMES) {
      title = 'Group service Sessions';
      searchPlaceHolder = 'Search for schedule name or session description...';
      canSearch = true;
      itemSingularName = ' session';
      itemPluralName = ' sessions';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.GROUP_SERVICE_SCHEDULES) {
      title = 'Group service Schedule';
      searchPlaceHolder = 'Search for schedule name...';
      canSearch = true;
      itemSingularName = ' schedule';
      itemPluralName = ' schedules';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.LOCATION_BY_SUBURBS) {
      title = 'Location (Suburb)';
      searchPlaceHolder = 'Search for suburb...';
      canSearch = true;
      itemSingularName = ' suburb';
      itemPluralName = ' suburbs';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.INVOICE_STATUS) {
      title = 'Invoice Status';
      itemSingularName = ' status';
      itemPluralName = ' status';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.WORKFLOW_TEMPLATE_TYPE) {
      title = 'Type';
      canSearch = false;
      fullSelectionName = 'All';
    } else if (filterType === FilterType.WORKFLOW_TEMPLATE_STATUS) {
      title = 'Status';
      canSearch = false;
      fullSelectionName = 'All';
    } else if (filterType === FilterType.ACTIVE_WORKFLOWS_DATE) {
      title = 'Date';
      canSearch = false;
      fullSelectionName = 'All';
    } else if (filterType === FilterType.ACTIVE_WORKFLOWS_STATUS) {
      title = 'Status';
      canSearch = false;
      itemSingularName = ' status';
      itemPluralName = ' status';
      fullSelectionName = 'All';
    } else if (
      filterType === FilterType.REQUIRE_APPROVAL_WORKFLOW_STATUS ||
      filterType === FilterType.ALL_WORKFLOWS_STATUS
    ) {
      title = 'Status';
      canSearch = false;
      itemSingularName = ' status';
      itemPluralName = ' status';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.SUPPORT_COORDINATOR) {
      title = 'Support coordinator';
      searchPlaceHolder = 'Search for coordinator...';
      canSearch = true;
      itemSingularName = ' support coordinator';
      itemPluralName = ' selected';
      fullSelectionName = 'Everyone';
    } else if (filterType === FilterType.CASE_MANAGER) {
      title = 'Case manager';
      searchPlaceHolder = 'Search for case manager...';
      canSearch = true;
      itemSingularName = ' case manager';
      itemPluralName = ' case managers';
      fullSelectionName = 'Everyone';
    } else if (filterType === FilterType.FORM_STATUS) {
      title = 'Status';
      itemSingularName = ' status';
      itemPluralName = ' status';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.FORM_TYPE) {
      title = 'Type';
      itemSingularName = ' form type';
      itemPluralName = ' form types';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.WORKFLOW_STATUS) {
      title = 'Status';
      itemSingularName = ' status';
      itemPluralName = ' status';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.SLEEPOVER) {
      title = 'Is sleepover booking';
      fullSelectionName = 'Both';
    } else if (filterType === FilterType.REJECTION_STATUS) {
      title = FilterLabel.rejectionStatus;
      fullSelectionName = 'Rejected invoices only';
      emptyName = 'All invoices';
    } else if (filterType === FilterType.AVAILABILITY_CHANGE_REQUESTS) {
      title = 'Availability change requests';
      fullSelectionName = 'Pending requests only';
      emptyName = ' ';
    } else if (filterType === FilterType.SERVICE_AGREEMENT_STATUS) {
      title = 'Status';
      searchPlaceHolder = 'Service Agreement status...';
      canSearch = true;
      itemSingularName = ' status';
      itemPluralName = ' status';
      fullSelectionName = 'All';
    } else if (filterType === FilterType.BOOKING_BILLING) {
      title = 'Billing';
      customTitle = 'Billable';
      itemSingularName = 'Only show';
    } else if (filterType === FilterType.PAID_BILLING || filterType === FilterType.BATCH_BILLING) {
      title = 'Billing';
      customTitle = 'Billing status';
      itemSingularName = ' only';
    } else if (filterType === FilterType.DATE_ADDED) {
      title = 'Date added';
    } else if (filterType === FilterType.DEBTOR) {
      title = 'Debtor';
      searchPlaceHolder = 'Search for debtor...';
      canSearch = true;
      itemSingularName = ' debtor';
      itemPluralName = ' debtors';
      fullSelectionName = 'Everyone';
    }

    return {
      title,
      searchPlaceHolder,
      canSearch,
      itemSingularName,
      itemPluralName,
      fullSelectionName,
      emptyName,
      customTitle,
    };
  }

  static getFilterText = (
    filterType: string,
    selectedFilter: string[],
    itemList = null,
    displayTimezone = null,
  ): string => {
    const filterSettings = CommonUtils.getFilterSettings(filterType);

    if (filterType === FilterType.SERVICE_DATE_TIMES || filterType === FilterType.GROUP_SERVICE_SCHEDULES) {
      const label = selectedFilter.length === 1 ? filterSettings.itemSingularName : filterSettings.itemPluralName;
      return selectedFilter.length === 0 ? filterSettings.fullSelectionName : selectedFilter.length + label;
    }

    if (
      filterType === FilterType.DATE_RANGE ||
      filterType === FilterType.ACTIVE_WORKFLOWS_DATE ||
      filterType === FilterType.DATE_ADDED
    ) {
      return !selectedFilter[0] && !selectedFilter[1]
        ? 'All'
        : !selectedFilter[1] || selectedFilter[0].isSame(selectedFilter[1], 'date')
        ? selectedFilter[0].isSame(moment.tz(displayTimezone), 'year')
          ? selectedFilter[0].format('D MMM')
          : selectedFilter[0].format('D MMM YYYY')
        : selectedFilter[1].isSame(moment.tz(displayTimezone), 'year')
        ? `${selectedFilter[0].format('D MMM')} - ${selectedFilter[1].format('D MMM')}`
        : `${selectedFilter[0].format('D MMM YYYY')} - ${selectedFilter[1].format('D MMM YYYY')}`;
    }

    const firstFilter = !_.isEmpty(itemList)
      ? _.find(itemList, (item) => item.value === selectedFilter[0])
      : selectedFilter[0];

    if (
      filterType === FilterType.AVAILABILITY_CHANGE_REQUESTS &&
      firstFilter === AvailabilityRequestType.YES &&
      selectedFilter.length === 1 &&
      !firstFilter.displayText
    ) {
      return filterSettings.fullSelectionName;
    }

    if (filterType === FilterType.BOOKING_BILLING) {
      return firstFilter && filterSettings.itemSingularName
        ? `${filterSettings.itemSingularName} ${firstFilter.value.toLowerCase()} items`
        : firstFilter.displayText;
    }

    if (filterType === FilterType.PAID_BILLING || filterType === FilterType.BATCH_BILLING) {
      return firstFilter && filterSettings.itemSingularName
        ? `${firstFilter.value} ${filterSettings.itemSingularName}`
        : firstFilter.displayText;
    }

    return selectedFilter.length > 0 && (_.isEmpty(itemList) || selectedFilter.length < itemList.length)
      ? selectedFilter.length === 1
        ? firstFilter && firstFilter.displayText
          ? firstFilter.displayText
          : '1 ' + filterSettings.itemSingularName
        : selectedFilter.length + ' ' + filterSettings.itemPluralName
      : selectedFilter.length === 0 && !Utils.isEmpty(filterSettings.emptyName)
      ? filterSettings.emptyName
      : filterSettings.fullSelectionName;
  };

  static findFilter(filterName, filters) {
    return _.find(filters, (filter) => filterName === filter.filter);
  }

  static findFilterValue(filterName, filters) {
    const filter = _.find(filters, (filter) => filterName === filter.filter);
    return filter ? filter.values : null;
  }

  static switchMMMGroupString(mmmGroup: MmmGroup): string {
    switch (mmmGroup) {
      case MmmGroup.NonRemote:
        return 'nonRemotePrice';
      case MmmGroup.Remote:
        return 'remotePrice';
      case MmmGroup.VeryRemote:
        return 'veryRemotePrice';
      default:
        return '';
    }
  }

  static getRecurringPatternLabel(recurringPattern) {
    const labels = ['Every day', 'Every week', 'Every 2 weeks', 'Every 4 weeks', 'Every 3 weeks'];
    return labels[recurringPattern - 1];
  }

  static findStandardPrice(lineItem, state, type) {
    const mmmNTBucket = [
      'NT',
      'Northern Territory',
      'SA',
      'South Australia',
      'TAS',
      'Tasmania',
      'WA',
      'Western Australia',
    ];

    const stateMMMBucket =
      state && _.find(mmmNTBucket, (bucketState) => bucketState.toLowerCase() === state.toLowerCase()) ? 0 : 1;
    const lineItemData = type === 'JSON' ? lineItem : ndisHelper.getBySupportItemNumber(lineItem.supportItemNumber);
    if (type === 'JSON') {
      if (lineItemData && lineItemData.NationalNonRemote !== '') {
        return lineItem.NationalNonRemote;
      } else {
        return stateMMMBucket === 0 ? lineItem.NTPrice : lineItem.ACTPrice;
      }
    } else {
      if ((lineItem.price && lineItem.price.nonRemotePrice !== '') || lineItem.nonRemotePrice !== '') {
        return lineItem.price ? lineItem.price.nonRemotePrice : lineItem.nonRemotePrice;
      } else {
        return stateMMMBucket === 0
          ? lineItem.price
            ? lineItem.price.ntPrice
            : lineItem.ntPrice
          : lineItem.price
          ? lineItem.price.actPrice
          : lineItem.actPrice;
      }
    }
  }

  static createAddressFromGoogleMap = (address_components) => {
    if (address_components && !_.isEmpty(address_components)) {
      const postal_code = _.find(address_components, (address) => {
        return address.types[0] === 'postal_code';
      });

      const country = _.find(address_components, (address) => {
        return address.types[0] === 'country';
      });

      const province = _.find(address_components, (address) => {
        return address.types[0] === 'administrative_area_level_1';
      });

      const locality = _.find(address_components, (address) => {
        return address.types[0] === 'locality';
      });

      const street_number = _.find(address_components, (address) => {
        return address.types[0] === 'street_number';
      });

      const route = _.find(address_components, (address) => {
        return address.types[0] === 'route';
      });

      const subpremise = _.find(address_components, (address) => {
        return address.types[0] === 'subpremise';
      });

      const streetAddress1 = route
        ? `${subpremise ? subpremise.long_name.replace(/\D/g, '') + '/' : ''}${
            street_number ? street_number.long_name : ''
          } ${route.long_name}`
        : '';

      return {
        state: province !== undefined ? province.short_name : '',
        country: country !== undefined ? country.long_name : '',
        locality: locality !== undefined ? locality.long_name : '',
        postcode: postal_code !== undefined ? postal_code.long_name : '',
        streetAddress1,
        streetAddress2: '',
        fullAddress: CommonUtils.formatFullAddress({
          streetAddress1,
          streetAddress2: '',
          locality: locality !== undefined ? locality.long_name : '',
          state: province !== undefined ? province.short_name : '',
          postcode: postal_code !== undefined ? postal_code.long_name : '',
          country: country !== undefined ? country.long_name : '',
        }),
      };
    } else {
      return {
        state: '',
        country: '',
        locality: '',
        postcode: '',
        streetAddress1: '',
        streetAddress2: '',
      };
    }
  };

  static formatPriceFrequencyString(priceFrenquency: PriceFrenquency): string {
    switch (priceFrenquency) {
      case PriceFrenquency.PERHOUR:
        return 'Hourly';
      case PriceFrenquency.PERDAY:
        return 'Daily';
      case PriceFrenquency.PERWEEK:
        return 'Weekly';
      case PriceFrenquency.PERMONTH:
        return 'Monthly';
      case PriceFrenquency.PERYEAR:
        return 'Annually';
      case PriceFrenquency.PEREACH:
        return 'Flat fee';
      default:
        return null;
    }
  }

  static getActiveConnectionRequestHelper = (selectedCustomer) => {
    if (!_.isEmpty(selectedCustomer)) {
      const now = moment();
      const activeConnectionRequest = _.filter(selectedCustomer.customerConnectionRequests, (request) => {
        const expiredDate = moment(request.tokenExpireDate);
        return (now.isBefore(expiredDate) && request.status === 'PENDING') || request.connected === 'CONNECTED';
      });

      return activeConnectionRequest;
    }
  };

  static getBackgroundColor(stringInput) {
    const stringUniqueHash = [...stringInput].reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
  }

  static formatQuantityDisplay = (qty: number) => {
    if (!qty) {
      return '-';
    }
    if (_.round(qty, 2) === Number(qty)) {
      return _.round(qty, 2).toFixed(2);
    }
    return qty;
  };

  static calculateTotalShiftClashes = (shiftClashConflicts: IShiftClashConflict[]): number => {
    if (_.isEmpty(shiftClashConflicts)) return 0;

    return shiftClashConflicts
      .map((shiftClashConflict) => {
        return shiftClashConflict.selectedShifts
          .map((shift) => shift.totalConflicts)
          .reduce((prev, next) => prev + next, 0); // total shift clash of 1 worker
      })
      .reduce((prev, next) => prev + next); //total shift clashes of all workers
  };

  static calcGstPriceByTotal(price: number | string, gstPercent: number) {
    const gstValue = gstPercent ? _.round(Number(price) / ((1 + gstPercent) * 10), 2) : 0;
    return { priceValue: Number(price) - gstValue, gstValue };
  }

  static calcGstValue(priceValue: number | string, gstPercent: number | string) {
    return _.round(Number(priceValue) * Number(gstPercent), 2);
  }

  static calcPriceIncludeGst(priceValue: number | string, gstValue: number | string) {
    return _.round(Number(priceValue) + Number(gstValue), 2);
  }

  static calcTotalPriceIncludeGst(priceValue: number | string, gstValue: number | string, quantity: number | string) {
    return _.round((Number(priceValue) + Number(gstValue)) * Number(quantity), 2);
  }

  static splitHourAndMinute = (number: string) => {
    const hours = Number.parseInt(number);
    let minutes;
    if (number.includes('.')) {
      minutes = number.slice(_.indexOf(number, '.') + 1, number.length);
    } else if (number.includes(',')) {
      minutes = number.slice(_.indexOf(number, ',') + 1, number.length);
    }
    return { hours, minutes };
  };

  static formatDurationFromMinutes = (durationInMinutes: number) => {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    let durationString = '';

    if (hours > 0 && minutes > 0) {
      durationString = `${hours}h ${minutes}m`;
    } else if (hours > 0) {
      durationString = `${hours}h`;
    } else {
      durationString = `${minutes}m`;
    }

    return durationString;
  };

  static formatDaysOfWeek = (days: string[]) => {
    const dayOrder = {
      MON: 0,
      TUE: 1,
      WED: 2,
      THU: 3,
      FRI: 4,
      SAT: 5,
      SUN: 6,
    };

    return days.sort((a, b) => dayOrder[a] - dayOrder[b]);
  };

  static formatServiceTypeStatus = (shift: IRosterShift | IRosterBooking, isBooking = false): string => {
    let status = '';

    if (shift.bookingType === BookingType.ACTIVITY_RECORD) {
      status = 'Activity record';
    } else if (shift.serviceType === ServiceType.INDIVIDUAL) {
      status = 'Support service';
    } else if (shift.serviceType === ServiceType.COORDINATION) {
      status = 'Support Coordination';
    } else if (isBooking) {
      status = 'Group booking';
    } else {
      status = 'Group service';
    }

    return status;
  };

  static formatFullName = (nameObj: { firstName?: string; lastName?: string }): string => {
    if (!nameObj) return null;
    return [nameObj.firstName ?? '', (nameObj.firstName && nameObj.lastName) ?? ''].join(' ').trim();
  };

  /**
 @method getMomentIsoWeekday
 @static
 @param moment (target date time)
 @param day (day in week current week 1 - 7 ~ mon - sun, zero for sunday, negative previous week, more than 7 is next week)
 @return adjusted iso moment date time by day
 */
  static getMomentIsoWeekday = (moment: moment.Moment, day: number) => {
    // 0 - 6 ~ mon - sun
    const momentDay = moment.day();
    // if target date time fall into sunday,
    // isoWeekday will target current week is in the past
    // solution: plus a week to the target
    return momentDay === 0 ? moment.add(1, 'week').isoWeekday(day) : moment.isoWeekday(day);
  };

  static getKeyFromEnumByValue<T extends Record<string, string>>(enumObj: T, value: string) {
    return Object.keys(enumObj).find((key) => enumObj[key] === value);
  }

  static getNumberOfDecimals(value: number) {
    if (value !== undefined && value % 1 !== 0) {
      const decimalPart = value.toString().split('.')[1];
      return decimalPart ? decimalPart.length : 0;
    }
    return 0;
  }
}

/**
 * @deprecated This has been deprecated in favour of named export. Please use the named export instead.
 */
export default CommonUtils;
