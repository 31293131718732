/* eslint-disable */
import {
  IAddBookingFormPayload,
  IBillingBooking,
  IListBookingsResult,
  ISleepoverTimeSlot,
} from 'interfaces/booking-interfaces';
import {
  BookingsList,
  FilterCounters,
  SetBookingListPayload,
  DoFetchBookingsPayload,
} from 'stores/rematch/models/types/bookings-store';
import _ from 'lodash';
import moment from 'moment';
import rootStore, { IRootState } from 'stores/rematch/root-store';
import apiClient from 'utilities/api-client';
import CommonUtils from 'utilities/common-utils';
import {
  BookingErrorType,
  CustomViewsType,
  DateFilterPeriodModes,
  FilterType,
  PaymentStatus,
  ServiceType,
  ShiftSlotStatus,
  TimezoneSelectorMode,
  BillableDisplayType,
} from 'utilities/enum-utils';
import Utils from 'utilities/Utils';
import { IElementValue } from 'views/form-builder/shared/form-interface';
import { events } from 'integrations/appcues';

export const formatBookingListingFilters = (payload) => {
  let body = { ...payload };
  if (body && body.filters) {
    // Transform the filters array into objects and remove the empty filters.
    const filters = _.chain(_.filter(body.filters, (filter) => !Utils.isEmpty(filter.values)))
      .keyBy('filter')
      .mapValues('values')
      .value();
    // Merge back the filters into the payload
    body = { ...body, ...filters };
    // Delete the 'filters' props to have a lighter payload.
    delete body.filters;

    if (body[FilterType.CUSTOMER]) {
      body[FilterType.CUSTOMER] = _.map(body[FilterType.CUSTOMER], (item) => item.value);
    }
    if (body[FilterType.WORKER]) {
      body[FilterType.WORKER] = _.map(body[FilterType.WORKER], (item) => item.value);
    }
    if (body[FilterType.SERVICE_DATE_TIMES]) {
      body[FilterType.SERVICE_DATE_TIMES] = _.map(body[FilterType.SERVICE_DATE_TIMES], (item) => item.value);
    }
    if (body[FilterType.GROUP_SERVICE_SCHEDULES]) {
      body[FilterType.GROUP_SERVICE_SCHEDULES] = _.map(body[FilterType.GROUP_SERVICE_SCHEDULES], (item) => item.value);
    }
    if (body[FilterType.LOCATION_BY_SUBURBS]) {
      body[FilterType.LOCATION_BY_SUBURBS] = _.map(body[FilterType.LOCATION_BY_SUBURBS], (item) => item.value);
    }

    if (body[FilterType.BOOKING_ERROR_TYPES]) {
      let errorFilter = [];
      body[FilterType.BOOKING_ERROR_TYPES].forEach((bookingErrorType) => {
        if (_.isArray(bookingErrorType)) {
          errorFilter = [...errorFilter, ...bookingErrorType];
        } else {
          errorFilter.push(bookingErrorType);
        }
      });
      if (
        _.find(errorFilter, (error) => error === BookingErrorType.BookingRecordedTimeWarning) &&
        !_.find(errorFilter, (error) => error === BookingErrorType.BookingActualTimeWarning)
      ) {
        errorFilter.push(BookingErrorType.BookingActualTimeWarning);
      } else if (_.find(errorFilter, (error) => error === BookingErrorType.BookingActualTimeWarning)) {
        errorFilter = _.filter(errorFilter, (error) => error !== BookingErrorType.BookingActualTimeWarning);
      }
      body[FilterType.BOOKING_ERROR_TYPES] = errorFilter;
    }

    if (body[FilterType.RECURRING]) {
      const isRecurringTrue = !!_.find(body[FilterType.RECURRING], (filter) => filter === 'YES');
      const isRecurringFalse = !!_.find(body[FilterType.RECURRING], (filter) => filter === 'NO');
      // If both yes and no are selected, remove the filter to get the full list
      if (isRecurringTrue && isRecurringFalse) {
        delete body[FilterType.RECURRING];
      } else {
        body[FilterType.RECURRING] = isRecurringTrue;
      }
    }

    if (body[FilterType.PINNED_ALERTS]) {
      const isPinnedAlertTrue = !!_.find(body[FilterType.PINNED_ALERTS], (filter) => filter === 'YES');
      const isPinnedAlertFalse = !!_.find(body[FilterType.PINNED_ALERTS], (filter) => filter === 'NO');
      // If both yes and no are selected, remove the filter to get the full list
      if (isPinnedAlertTrue && isPinnedAlertFalse) {
        delete body[FilterType.PINNED_ALERTS];
      } else {
        body[FilterType.PINNED_ALERTS] = isPinnedAlertTrue;
      }
    }

    if (body[FilterType.SLEEPOVER]) {
      const isSleepoverTrue = _.some(body[FilterType.SLEEPOVER], (filter) => filter === 'YES');
      const isSleepoverFalse = _.some(body[FilterType.SLEEPOVER], (filter) => filter === 'NO');
      // If both yes and no are selected, remove the filter to get the full list
      if (isSleepoverTrue && isSleepoverFalse) {
        delete body[FilterType.SLEEPOVER];
      } else {
        body[FilterType.SLEEPOVER] = isSleepoverTrue;
      }
    }

    if (body[FilterType.BOOKING_BILLING]) {
      body[FilterType.BOOKING_BILLING] = body[FilterType.BOOKING_BILLING] === BillableDisplayType.NON_BILLABLE;
    }
  }

  return body;
};

const formatFilters = (filters) => {
  return filters
    .filter((filter) => !_.isEmpty(filter))
    .map((filter) => {
      if (_.isUndefined(filter.filter)) {
        const [[key, value]] = Object.entries(filter);
        return {
          filter: key,
          values: value || [],
          selectionLabel: !_.isEmpty(value)
            ? key === FilterType.DATE_RANGE
              ? CommonUtils.getFilterText(FilterType.DATE_RANGE, [moment(value[0]), moment(value[1])])
              : CommonUtils.getFilterText(key, value)
            : CommonUtils.getFilterSettings(key).fullSelectionName,
        };
      }
      return filter;
    });
};

const mapBookingViewFilterPayload = (filterValue) => {
  const payload =
    filterValue &&
    _.cloneDeep(filterValue)
      .filter((filter) => !Object.keys(filter).includes('search'))
      .map((filter) => {
        if (Object.keys(filter).includes(FilterType.CUSTOMER)) {
          filter[FilterType.CUSTOMER] = _.map(filter[FilterType.CUSTOMER], (customer) => {
            const { name, value, displayText } = customer;
            return { displayText: name || displayText, value };
          });
        }
        return filter;
      });
  return payload;
};

const bookingsList: BookingsList = {};
const filterCounters: FilterCounters = [];

const bookingsStore = {
  state: {
    bookingsList,
    filteredBookingList: [],
    selectedBookingItem: null,
    selectedFilterKey: null,
    bookingsFilter: [],
    bookingServiceList: [],
    bookingCustomerList: [],
    dashboardData: null,
    selectedServiceRoster: [],
    filteredServiceRoster: [],
    bookingResponseData: [],
    newBookingData: [],
    selectedBookings: [],
    bookingsSelectedAll: false,
    bookingProcessStatus: true,
    isAwaitingBookActions: false,
    selectedNote: null,
    filterCounters,
    publishWorkersList: null,
    publishSettings: null,
    bookingDisplayTzMode: TimezoneSelectorMode.MyTimezone,
    bookingDisplayTzCustom: null,
    selectedListingPeriodFilterMode: DateFilterPeriodModes.ALL,
    listingFilterOrder: null,
    hasBookingListingFilterChanged: false,
    customerBookingPaymentDetail: null,
    customerBookingSuggestionPaymentDetail: null,
    recurringBookingList: [],
    bookingRecurringPattern: null,
    newActivityRecordData: {},
    suburbs: [],
    bookingListingActiveTab: null,
    bookingViews: [],
    displayedBookingListingTabs: [],
    isBookingListingLoading: false,
    defaultBookingViews: [
      {
        customViewId: 'ALL',
        name: 'All Bookings',
        isPinned: true,
        isDefault: true,
        viewType: CustomViewsType.EVERYONE,
        filterValue: [
          {
            filter: FilterType.DATE_RANGE,
            values: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
            selectionLabel: CommonUtils.getFilterText(FilterType.DATE_RANGE, [
              moment().startOf('isoWeek'),
              moment().endOf('isoWeek'),
            ]),
          },
          { filter: 'sort', values: [['startDateTime', 'asc']] },
          {
            filter: FilterType.CUSTOMER,
            values: [],
            selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName,
          },
          {
            filter: FilterType.WORKER,
            values: [],
            selectionLabel: CommonUtils.getFilterSettings(FilterType.WORKER).fullSelectionName,
          },
          {
            filter: FilterType.SHIFT_SLOT_STATUS,
            values: [],
            selectionLabel: CommonUtils.getFilterSettings(FilterType.SHIFT_SLOT_STATUS).fullSelectionName,
          },
          {
            filter: FilterType.SERVICE_TYPE,
            values: [ServiceType.INDIVIDUAL],
            selectionLabel: 'Support services',
          },
        ],
      },
      {
        customViewId: 'NO_WORKER_CONFIRMED',
        name: 'No team members confirmed',
        isPinned: true,
        isDefault: true,
        viewType: CustomViewsType.EVERYONE,
        filterValue: [
          {
            filter: FilterType.DATE_RANGE,
            values: [],
            selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName,
          },
          {
            filter: FilterType.CUSTOMER,
            values: [],
            selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName,
          },
          {
            filter: FilterType.WORKER,
            values: [],
            selectionLabel: CommonUtils.getFilterSettings(FilterType.WORKER).fullSelectionName,
          },
          {
            filter: FilterType.SHIFT_SLOT_STATUS,
            values: ['UNASSIGNED', 'PENDING'],
            selectionLabel: CommonUtils.getFilterText(FilterType.SHIFT_SLOT_STATUS, [
              'UNASSIGNED',
              'PENDING',
              'PUBLISHED',
            ]),
          },
          {
            filter: FilterType.BOOKING_STATUS,
            values: ['ACCEPTED', 'CONFIRMED', 'PENDING'],
            selectionLabel: CommonUtils.getFilterText(FilterType.BOOKING_STATUS, ['ACCEPTED', 'CONFIRMED', 'PENDING']),
          },
          {
            filter: FilterType.SERVICE_TYPE,
            values: [ServiceType.INDIVIDUAL],
            selectionLabel: 'Support services',
          },
          { filter: 'sort', values: [['startDateTime', 'asc']] },
        ],
      },
      {
        customViewId: 'OVERDUE',
        name: 'Overdue',
        isPinned: true,
        isDefault: true,
        viewType: CustomViewsType.EVERYONE,
        filterValue: [
          {
            filter: FilterType.DATE_RANGE,
            values: [moment().add(-1, 'year'), moment().startOf('day')],
            selectionLabel: CommonUtils.getFilterText(FilterType.DATE_RANGE, [
              moment().add(-1, 'year'),
              moment().startOf('day'),
            ]),
          },
          {
            filter: FilterType.CUSTOMER,
            values: [],
            selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName,
          },
          {
            filter: FilterType.WORKER,
            values: [],
            selectionLabel: CommonUtils.getFilterSettings(FilterType.WORKER).fullSelectionName,
          },
          {
            filter: FilterType.BOOKING_STATUS,
            values: ['PENDING', 'ACCEPTED', 'CONFIRMED'],
            selectionLabel: CommonUtils.getFilterText(FilterType.BOOKING_STATUS, ['PENDING', 'ACCEPTED', 'CONFIRMED']),
          },
          {
            filter: FilterType.SERVICE_TYPE,
            values: [ServiceType.INDIVIDUAL],
            selectionLabel: 'Support services',
          },
          { filter: 'sort', values: [['startDateTime', 'asc']] },
        ],
      },
      {
        customViewId: 'REQUIRED_APPROVAL',
        name: 'Requires approval',
        isPinned: true,
        isDefault: true,
        viewType: CustomViewsType.EVERYONE,
        filterValue: [
          {
            filter: FilterType.DATE_RANGE,
            values: [],
            selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName,
          },
          {
            filter: FilterType.CUSTOMER,
            values: [],
            selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName,
          },
          {
            filter: FilterType.WORKER,
            values: [],
            selectionLabel: CommonUtils.getFilterSettings(FilterType.WORKER).fullSelectionName,
          },
          {
            filter: FilterType.PAYMENT_STATUS,
            values: ['REQUIRES_APPROVAL'],
            selectionLabel: 'Requires approval',
          },
          {
            filter: FilterType.SERVICE_TYPE,
            values: [ServiceType.INDIVIDUAL],
            selectionLabel: 'Support services',
          },
          { filter: 'sort', values: [['startDateTime', 'asc']] },
        ],
      },
    ],
  },

  reducers: {
    setBookingList: (state, payload: SetBookingListPayload) => {
      if (payload === null) {
        return { ...state, bookingsList: {} };
      }

      const newState = { ...state };

      payload.forEach((item) => {
        if (item.filterId) {
          if (item.bookings === null) {
            newState.bookingsList[item.filterId] = [];
            return;
          }

          const currentBookings = newState.bookingsList[item.filterId] ?? [];
          newState.bookingsList[item.filterId] = [...currentBookings, ...item.bookings];
        }
      });

      return newState;
    },
    setPaymentList: (state, payload) => ({ ...state, paymentsList: payload }),
    setFilteredBookingList: (state, payload) => ({ ...state, filteredBookingList: payload }),
    setSelectedBookingItem: (state, payload) => ({ ...state, selectedBookingItem: payload }),
    setSelectedFilterKey: (state, payload) => ({ ...state, selectedFilterKey: payload }),
    setBookingsFilter: (state, payload) => {
      const { bookingListingActiveTab } = state;

      return {
        ...state,
        bookingsFilter: payload,
        bookingListingActiveTab: _.set(bookingListingActiveTab, 'filterValue', payload),
      };
    },
    setBookingCustomerList: (state, payload) => ({ ...state, bookingCustomerList: payload }),
    setBookingServiceList: (state, payload) => ({ ...state, bookingServiceList: payload }),
    setDashboardData: (state, payload) => ({ ...state, dashboardData: payload }),
    setFilterCounters: (state, payload) => ({ ...state, filterCounters: payload }),
    setSelectedListingPeriodFilterMode: (state, payload) => ({ ...state, selectedListingPeriodFilterMode: payload }),
    setSelectedListingPeriod: (state, payload) => ({ ...state, selectedListingPeriod: payload }),
    setListingFilterOrder: (state, payload) => ({ ...state, listingFilterOrder: payload }),
    setHasBookingListingFilterChanged: (state, payload) => ({ ...state, hasBookingListingFilterChanged: payload }),
    setSuburbs: (state, payload) => ({ ...state, suburbs: payload }),
    setBillingLineItems: (state, payload) => {
      const newState = { ...state };
      newState.selectedBookingItem.billingLineItems = payload;
      return newState;
    },
    setPaymentStatus: (state, payload) => {
      const newState = { ...state };
      newState.selectedBookingItem.paymentStatus = payload.paymentStatus;
      // TODO: set paymentStatus in bookingList
      return newState;
    },
    setSelectedServiceRoster: (state, payload) => ({ ...state, selectedServiceRoster: payload }),
    setFilteredServiceRoster: (state, payload) => ({ ...state, filteredServiceRoster: payload }),
    setBookingResponseData: (state, payload) => ({ ...state, bookingResponseData: payload }),
    setNewBookingData: (state, payload) => ({ ...state, newBookingData: payload }),
    setSelectedBookings: (state, payload) => ({ ...state, bookingsSelectedAll: false, selectedBookings: payload }),
    setPublishWorkersList: (state, payload) => ({ ...state, publishWorkersList: payload }),
    setPublishSettings: (state, payload) => ({ ...state, publishSettings: payload }),

    // Add by Jir : Used to indicate if there is a batch booking action currently in progress
    setAwaitingBookActions: (state, payload) => ({ ...state, isAwaitingBookActions: payload }),

    setBookingsSelected(state, selected: boolean) {
      const selectedBookings = _.map(state.selectedBookings, (booking) => {
        booking.selected = selected;
        return booking;
      });
      return { ...state, selectedBookings, bookingsSelectedAll: selected };
    },

    setIndividualBillingBookingSelected(state, payload) {
      const selectedBookings = _.map(state.selectedBookings, (booking) => {
        if (booking.bookingId === payload.bookingId) {
          booking.selected = payload.selected;
        }
        return booking;
      });
      const selected = _.every(selectedBookings, ['selected', true]);
      return { ...state, selectedBookings, bookingsSelectedAll: selected };
    },
    setBookingProcessStatus: (state, payload) => ({ ...state, bookingProcessStatus: payload }),
    setSelectedNote: (state, payload) => ({ ...state, selectedNote: payload }),
    setBookingDisplayTzMode: (state, payload) => ({ ...state, bookingDisplayTzMode: payload }),
    setBookingDisplayTzCustom: (state, payload) => ({ ...state, bookingDisplayTzCustom: payload }),
    setCustomerBookingPaymentDetail: (state, payload) => ({ ...state, customerBookingPaymentDetail: payload }),
    setCustomerBookingSuggestionPaymentDetail: (state, payload) => ({
      ...state,
      customerBookingSuggestionPaymentDetail: payload,
    }),
    setRecurringBookingList: (state, payload) => ({ ...state, recurringBookingList: payload }),
    setBookingRecurringPattern: (state, payload) => ({ ...state, bookingRecurringPattern: payload }),
    updateExtendedRecurrence(state, payload) {
      return {
        ...state,
        bookingRecurringPattern: { ...state.bookingRecurringPattern, recurringTo: payload.newEndDate },
        recurringBookingList: _.sortBy(payload.newShiftSlots, 'startDateTime'),
        selectedBookingItem: state.selectedBookingItem
          ? {
              ...state.selectedBookingItem,
              numberOfBookingLeft: state.selectedBookingItem.numberOfBookingLeft + payload.timeSlots.length,
            }
          : state.selectedBookingItem.numberOfBookingLeft,
      };
    },
    setNewActivityRecordData: (state, payload) => ({ ...state, newActivityRecordData: payload }),
    setBookingDocuments: (state, payload) => ({
      ...state,
      selectedBookingItem: { ...state.selectedBookingItem, bookingDocuments: payload },
    }),
    addBookingDocument: (state, payload) => {
      const existingDocuments = state.selectedBookingItem.bookingDocuments;
      const addedDocument = [
        {
          documentId: payload.documentId,
          documentName: payload.documentName,
          description: payload.description,
          createdOn: new Date(),
          documentUrl: null,
          status: 'SCANNING',
          firstName: payload.firstName,
          lastName: payload.lastName,
          visibleType: payload.visibleType,
        },
      ];
      return {
        ...state,
        selectedBookingItem: {
          ...state.selectedBookingItem,
          bookingDocuments: addedDocument.concat(existingDocuments),
        },
      };
    },
    removeBookingDocument: (state, payload) => ({
      ...state,
      selectedBookingItem: {
        ...state.selectedBookingItem,
        bookingDocuments: _.filter(
          state.selectedBookingItem.bookingDocuments,
          (document) => document.documentId !== payload.documentId,
        ),
        notes: payload.noteId
          ? _.map(state.selectedBookingItem.notes, (note) => {
              if (note.documentId === payload.documentId) {
                return {
                  ...note,
                  documentId: null,
                  documentName: null,
                  documentUrl: null,
                  documentStatus: null,
                };
              } else {
                return { ...note };
              }
            })
          : state.selectedBookingItem.notes,
      },
    }),
    setBookingDocumentStatus: (state, payload) => {
      const updatedDocuments = _.map(state.selectedBookingItem.bookingDocuments, (document) => {
        if (document.documentId === payload.documentId) {
          return {
            ...document,
            status: payload.status,
            documentUrl: payload.documentUrl,
          };
        } else {
          return { ...document };
        }
      });
      const notes = _.map(state.selectedBookingItem.notes, (note) => {
        if (note.documentId === note.documentId) {
          return {
            ...note,
            documentStatus: payload.status,
            documentUrl: payload.documentUrl,
          };
        } else {
          return { ...note };
        }
      });
      return {
        ...state,
        selectedBookingItem: { ...state.selectedBookingItem, bookingDocuments: updatedDocuments, notes },
      };
    },
    editBookingDocument: (state, payload) => {
      return {
        ...state,
        selectedBookingItem: {
          ...state.selectedBookingItem,
          bookingDocuments: _.map(state.selectedBookingItem.bookingDocuments, (document) => {
            if (document.documentId === payload.documentId) {
              return {
                ...document,
                description: payload.description,
                visibleType: payload.visibleType,
              };
            } else {
              return { ...document };
            }
          }),
        },
      };
    },
    setBookingListingActiveTab: (state, payload) => ({ ...state, bookingListingActiveTab: payload }),
    setBookingViews: (state, payload) => ({ ...state, bookingViews: payload }),
    setDisplayedBookingListingTabs: (state, payload) => {
      const { bookingViews } = state;
      const pinnedViews = bookingViews.filter((view) => view.isPinned);
      const views = [...pinnedViews, ...payload];
      const uniqueTabs = _.uniqBy(views, 'customViewId');
      return { ...state, displayedBookingListingTabs: uniqueTabs };
    },
    setIsBookingListingLoading: (state, payload) => ({ ...state, isBookingListingLoading: payload }),
    setFormStatus: (state, payload) => {
      const { attendanceFormId, status } = payload;
      const selectedBookingItem = state.selectedBookingItem;
      const forms = [...selectedBookingItem.forms];

      forms.forEach((form) => {
        if (form.attendanceFormId === attendanceFormId) {
          form.status = status;
        }
      });

      return {
        ...state,
        selectedBookingItem: {
          ...selectedBookingItem,
          forms,
        },
      };
    },
  },

  effects: (dispatch) => ({
    async doFetchBookings(payload: DoFetchBookingsPayload, rootState) {
      const views = payload.views ? payload.views : [payload];
      const requestPayload = views.map((view) => ({
        id: view.id,
        ...formatBookingListingFilters({ ...view, filters: formatFilters(view.filters) }),
      }));

      const result: IListBookingsResult = await apiClient.post(`/api/portal/bookings/list`, requestPayload);
      const bookingList: SetBookingListPayload = [];

      try {
        const hasData = result?.data?.length;

        if (hasData) {
          let newFilterCounters: FilterCounters = [...rootState.bookingsStore.filterCounters];

          result.data.forEach((result) => {
            bookingList.push({ bookings: result.bookingList, filterId: result.filterCounter?.filterName });

            newFilterCounters = [
              ...newFilterCounters.filter((fc) => fc?.filterName !== result.filterCounter?.filterName),
              result.filterCounter,
            ];
          });

          dispatch.bookingsStore.setBookingList(bookingList);
          dispatch.bookingsStore.setFilterCounters(newFilterCounters);
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
      return bookingList;
    },

    async doFetchFilteredBookingList(payload) {
      try {
        let res: IListBookingsResult = await apiClient.post('/api/portal/bookings/list', payload);
        return res.data?.[0]?.bookingList ?? [];
      } catch (err) {
        throw err;
      }
    },

    // check worker availability for create a new booking

    async doCheckAvailabilityBooking(payload, rootState) {
      const endPoint = 'api/portal/bookings/worker-time';
      try {
        let result = await apiClient.post(endPoint, payload);
        return result.data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    // set all new booking data in object

    async doSetNewBookingData(payload, rootState) {
      const bookData = {
        selectedCustomerId: payload.selectedCustomerId,
        selectedCustomer: payload.selectedCustomer,
        selectedServiceId: payload.selectedServiceId,
        selectedService: payload.selectedService,
        selectedWorkerId: payload.selectedWorkerId,
        selectedWorker: payload.selectedWorker,
        bookStartDate:
          payload.bookStartDate !== undefined && payload.bookStartDate !== ''
            ? payload.bookStartDate
            : moment().toDate(),
        bookEndDate:
          payload.bookEndDate !== undefined && payload.bookEndDate !== ''
            ? payload.bookEndDate
            : moment().add(1, 'hour').toDate(),
        bookStartTime: payload.bookStartTime,
        bookEndTime: payload.bookEndTime,
        bookLocation: payload.bookLocation,
        bookCustomNote: payload.bookCustomNote,
        hasMileageClaims: payload.hasMileageClaims,
        mileageTravelled: payload.mileageTravelled,
        isCancelledBooking: !!payload.isCancelledBooking,
        cancellationReason: payload.cancellationReason,
        cancellationCode: payload.cancellationCode,
        isFutureBooking: payload.isFutureBooking,
        serviceDateTimeId: payload.serviceDateTimeId,
        supportWorkers: payload.supportWorkers,
        workerGroups: payload.workerGroups,
        isShiftPublished: payload.isShiftPublished,
        isRecurring: payload.isRecurring,
        recurringPattern: payload.recurringPattern,
        conflictBookings: payload.conflictBookings,
        timeSlots: payload.timeSlots,
        timeSlotsOutSideCanBeAssign: payload.timeSlotsOutSideCanBeAssign,
        conflictWorkerBookings: payload.conflictWorkerBookings,
        manuallyAddedAddress: payload.manuallyAddedAddress,
        isTravelBeforeBooking: payload.isTravelBeforeBooking,
        travelDistanceBeforeBooking: payload.travelDistanceBeforeBooking,
        travelTimeBeforeBooking: payload.travelTimeBeforeBooking,
        additionalCostBeforeBooking: payload.additionalCostBeforeBooking,
        isTravelDuringBooking: payload.isTravelDuringBooking,
        travelDistanceDuringBooking: payload.travelDistanceDuringBooking,
        additionalCostDuringBooking: payload.additionalCostDuringBooking,
        selectedLineItems: payload.selectedLineItems,
        paymentSourceType: payload.paymentSourceType,
        shiftSlotStatus: payload.shiftSlotStatus,
        isRemovePendingShiftSlots: payload.isRemovePendingShiftSlots,
        ignoreShiftClashShiftIds: payload.ignoreShiftClashShiftIds,
        ignoreShiftClashServiceDateTimeIds: payload.ignoreShiftClashServiceDateTimeIds,
        sleepoverType: payload.sleepoverType,
        sleepoverTimeSlots: payload.sleepoverTimeSlots,
        sleepoverTimeSlotsRecurring: payload.sleepoverTimeSlotsRecurring,
      };
      dispatch.bookingsStore.setNewBookingData(bookData);
    },

    // create new booking
    async doCreateNewBooking(payload, rootState) {
      if (payload.bookingType !== ServiceType.INDIVIDUAL && payload.bookingType !== ServiceType.COORDINATION) {
        throw Error('Selected service must be a Support service');
      }
      // TODO: Re-activate when create group is updated
      // const endPoint =
      //   payload.bookingType === ServiceType.INDIVIDUAL
      //     ? 'api/portal/bookings/individual/create'
      //     : 'api/portal/bookings/group/create';
      const endPoint = 'api/portal/bookings/individual/create';
      try {
        const result = await apiClient.post(endPoint, payload);
        // Appcues
        const bookingId = result?.data ? result?.data[0]?.bookingId : '';
        const memberId = payload?.workerId;
        const supportWorkers = payload?.supportWorkers;
        const eventData = {
          serviceType: payload.bookingType,
        };

        if (supportWorkers && supportWorkers.length)
          events.trackPublishBookingToMember({ bookingId, totalSupportWorker: supportWorkers.length });

        if (memberId) events.trackAssignMemberToBooking({ ...eventData, memberId });

        events.trackCreateBooking({ ...eventData, bookingId });

        if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
          dispatch.bookingsStore.setBookingResponseData(result.data);
        } else {
          return result;
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    // ! filter out list of changed bookings (status)
    updateBookings(state, payload) {
      const selectedBookings = _.filter(state.selectedBookings, (booking) => {
        return !_.find(payload, (i) => i === booking.bookingId);
      });

      const selected = _.every(selectedBookings, ['selected', true]);

      return { ...state, selectedBookings, bookingsSelectedAll: selected };
    },

    async doApproveBookingLineItems(payload, rootState) {
      const fullUrl = '/api/portal/billing/approve';

      const bookingIds = _.chain(rootState.bookingsStore.selectedBookings)
        .filter((booking) => booking.selected === true)
        .map((booking) => {
          return booking.bookingId;
        })
        .value();

      try {
        const resp = await apiClient.put(fullUrl, { bookingIds: bookingIds });
        if (resp.status === 200) {
          //TODO need to check against booking status to hide/show
          dispatch.bookingsStore.updateBookings(bookingIds);
          return true;
        }
      } catch (err) {
        throw err;
      }
    },

    async doSendToFinanceBookingLineItems(payload, rootState) {
      const fullUrl = '/api/portal/billing/send-to-finance';

      const bookingIds = _.chain(rootState.bookingsStore.selectedBookings)
        .filter((booking) => booking.selected === true)
        .map((booking) => {
          return booking.bookingId;
        })
        .value();

      try {
        const resp = await apiClient.put(fullUrl, { bookingIds: bookingIds });
        if (resp.status === 200) {
          dispatch.bookingsStore.updateBookings(bookingIds);
          return true;
        }
      } catch (err) {
        throw err;
      }
    },

    // fetch single booking
    async doFetchSingleBooking(payload, rootState) {
      const { bookingId } = payload;

      try {
        let result = await apiClient.get(`/api/portal/bookings/${bookingId}`);

        if (!_.isEmpty(result.data)) {
          dispatch.bookingsStore.setCustomerBookingPaymentDetail(null);
          dispatch.bookingsStore.setSelectedBookingItem(result.data);
        } else {
          throw new Error('warning - no booking item found');
        }

        // TODO do we need validation here?
        return result.data;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    async doFetchWorkerSearchList(payload, rootState) {
      try {
        let result = await apiClient.post(`api/portal/bookings/shifts/search-target-workers`, payload);
        return result.data;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    async doFetchPublishSettings(payload, rootState) {
      try {
        // BookingId is the AttendanceId
        let result = await apiClient.post(`api/portal/bookings/${payload.bookingId}/shifts/publish-setting`, payload);

        dispatch.bookingsStore.setPublishSettings(result.data);
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    async doAcceptBooking(payload, rootState) {
      const { bookingId } = payload;

      const body = {
        bookingId: bookingId,
      };

      try {
        let result = await apiClient.put(`/api/portal/bookings/individual/accept`, body);

        // note - why do the below? because the result returned from accept is not the same format as get booking details

        // TODO better way of doing this maybe?
        // Update selected booking
        const updatedData = result.data;

        const selectedBookingItem = rootState.bookingsStore.selectedBookingItem;
        const updatedBookingItem = { ...selectedBookingItem, ...updatedData };
        dispatch.bookingsStore.setSelectedBookingItem(updatedBookingItem);

        // TODO Maybe can just directly update in array. Do later, no time now.
        dispatch.bookingsStore.doFetchBookings();
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doConfirmBooking(payload, rootState) {
      const { bookingId } = payload;

      const body = {
        bookingId: bookingId,
      };

      try {
        let result = await apiClient.put(`/api/portal/bookings/individual/confirm`, body);
        const updatedBookingItem = { ...rootState.bookingsStore.selectedBookingItem, ...result.data };
        dispatch.bookingsStore.setSelectedBookingItem(updatedBookingItem);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doCheckinBooking(payload, rootState) {
      const { checkInTime } = payload;
      const selectedBookingItem = rootState.bookingsStore.selectedBookingItem;
      const data = {
        bookingId: selectedBookingItem.bookingId,
        checkInTime: checkInTime,
      };
      try {
        let result = await apiClient.put(`/api/portal/bookings/individual/checkin`, data);
        const updatedBookingItem = { ...rootState.bookingsStore.selectedBookingItem, ...result.data };
        dispatch.bookingsStore.setSelectedBookingItem(updatedBookingItem);
      } catch (e) {
        throw e;
      }
    },

    async doCheckoutBooking(payload, rootState) {
      const selectedBookingItem = rootState.bookingsStore.selectedBookingItem;
      const body = { ...payload, bookingId: selectedBookingItem.bookingId };
      try {
        let result = await apiClient.put(`/api/portal/bookings/individual/checkout`, body);
        const updatedBookingItem = { ...rootState.bookingsStore.selectedBookingItem, ...result.data };
        dispatch.bookingsStore.setSelectedBookingItem(updatedBookingItem);
      } catch (e) {
        throw e;
      }
    },

    async doCompleteBooking(payload, rootState) {
      const selectedBookingItem = rootState.bookingsStore.selectedBookingItem;
      const body = { ...payload, bookingId: selectedBookingItem.bookingId };
      try {
        await apiClient.put(`/api/portal/bookings/individual/complete`, body);
        await dispatch.bookingsStore.doFetchSingleBooking({ bookingId: selectedBookingItem.bookingId });
      } catch (e) {
        throw e;
      }
    },

    async doRejectBooking(payload, rootState) {
      const { bookingId, reason } = payload;

      const body = {
        bookingId: bookingId,
        reason: reason,
      };

      try {
        let result = await apiClient.put(`/api/portal/bookings/individual/reject`, body);
        const updatedBookingItem = { ...rootState.bookingsStore.selectedBookingItem, ...result.data };
        dispatch.bookingsStore.setSelectedBookingItem(updatedBookingItem);

        dispatch.bookingsStore.doFetchBookings();
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doFinishBooking(payload, rootState) {
      const { bookingId } = payload;

      const body = {
        bookingId: bookingId,
      };

      try {
        let result = await apiClient.put(`/api/portal/bookings/individual/complete`, body);
        const updatedBookingItem = { ...rootState.bookingsStore.selectedBookingItem, ...result.data };
        dispatch.bookingsStore.setSelectedBookingItem(updatedBookingItem);

        dispatch.bookingsStore.doFetchBookings();
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doAssignWorker(payload, rootState) {
      const endpoint = `/api/portal/bookings/${payload.bookingId}/individual/assign`;
      try {
        let result = await apiClient.put(endpoint, payload);
        const updatedBookingItem = {
          ...rootState.bookingsStore.selectedBookingItem,
          isShiftPublished: false,
          ...result.data,
        };
        if (!payload.doNotSetBookingItem) {
          dispatch.bookingsStore.setSelectedBookingItem(updatedBookingItem);
        }
        await this.doFetchSingleBooking({ bookingId: payload.bookingId });
        const { serviceName, status, workerFirstName, workerLastName, firstName, lastName } = result?.data;

        events.trackAssignMemberToBooking({
          bookingId: payload.bookingId,
          memberId: payload?.supportWorkerId,
          serviceName,
          bookingStatus: status,
          teamMemberName: `${workerFirstName} ${workerLastName}`,
          customerName: `${firstName} ${lastName}`,
        });

        return result;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doRemoveWorker(payload, rootState) {
      const endpoint = `api/portal/bookings/${payload.bookingId}/individual/assign`;

      try {
        let result = await apiClient.delete(endpoint, payload);
        const updatedBookingItem = {
          ...rootState.bookingsStore.selectedBookingItem,
          ...result.data,
          shiftSlotStatus: ShiftSlotStatus.UNASSIGNED,
          workerId: null,
          workerUserId: null,
          workerFirstName: null,
          workerLastName: null,
          workerAttachmentPath: null,
          workerAttachmentUrl: null,
        };
        dispatch.bookingsStore.setSelectedBookingItem(updatedBookingItem);
        await this.doFetchSingleBooking({ bookingId: payload.bookingId });
        return result;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doBulkRemoveTeamMembersBookingListing(payload, rootState) {
      const endpoint = 'api/portal/bookings/individual/assign';

      try {
        let result = await apiClient.delete(endpoint, payload);
        return result;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doBulkCancelBookingsBookingListing(payload, rootState) {
      const endpoint = 'api/portal/bookings/individual/cancel';

      try {
        let result = await apiClient.put(endpoint, payload);
        return result;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doPublishToWorkers(payload, rootState) {
      const { bookingId, supportWorkers, workerGroups, isShiftPublished } = payload;
      const endpoint = `api/portal/bookings/${bookingId}/shifts/publish`;
      const request = { supportWorkers, attendanceId: bookingId, workerGroups };

      try {
        let result = isShiftPublished
          ? await apiClient.put(endpoint, request)
          : await apiClient.post(endpoint, request);
        dispatch.bookingsStore.setSelectedBookingItem({
          ...rootState.bookingsStore.selectedBookingItem,
          isShiftPublished: true,
          publishShiftDate: new Date(),
          ...result.data,
        });

        events.trackPublishBookingToMember({
          bookingId,
          totalSupportWorker: supportWorkers.length,
        });

        return result;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doUnpublishShift(payload, rootState) {
      const { bookingId, serviceId } = payload;
      const endpoint = `api/portal/bookings/${bookingId}/shifts/unpublish`;
      const request = { attendanceId: bookingId, serviceId };

      try {
        let result = await apiClient.post(endpoint, request);
        dispatch.bookingsStore.setSelectedBookingItem({
          ...rootState.bookingsStore.selectedBookingItem,
          activeApplicants: result.data.activeApplicants,
          unsuccessfulApplicants: result.data.unsuccessfulApplicants,
          isShiftPublished: false,
          publishShiftDate: null,
          bookingHistories: result.data.bookingHistories,
        });

        return result;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doAddNote(payload, rootState) {
      const endpoint = `api/portal/bookings/${payload.bookingId}/notes`;
      try {
        let result = await apiClient.post(endpoint, payload);

        await dispatch.bookingsStore.doFetchSingleBooking({
          bookingId: rootState.bookingsStore.selectedBookingItem.bookingId,
        });
        return result.data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doUpdateNote(payload, rootState) {
      const { noteContent, isPrivate } = payload;
      const selectedBookingItem = rootState.bookingsStore.selectedBookingItem;
      const endPoint = `/api/portal/bookings/note`;
      const request = {
        noteContent,
        isPrivate,
        bookingId: selectedBookingItem.bookingId,
        noteId: rootState.bookingsStore.selectedNote.noteId,
      };
      try {
        let result = await apiClient.put(endPoint, request);

        selectedBookingItem.notes = selectedBookingItem.notes.map((note) => {
          if (note.noteId === rootState.bookingsStore.selectedNote.noteId) {
            note.body = noteContent;
          }
          return note;
        });
        dispatch.bookingsStore.setSelectedBookingItem(selectedBookingItem);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    // 2022-01-04
    // this is not being used
    // it is currently broken due to structure of the bookingsList
    doApplyFilter(payload, rootState) {
      if (_.isEmpty(payload)) {
        dispatch.bookingsStore.setBookingsFilter(null);
        dispatch.bookingsStore.setFilteredBookingList(rootState.bookingsStore.bookingsList);
      } else {
        const filters = _.chain(payload)
          .keys()
          .filter((key) => !_.isEmpty(payload[key]))
          .map((key) => {
            let filterFunction = (item) => true;

            const value = _.map(payload[key], (item) => {
              return item.value;
            });
            if (key === 'bookingStatus') {
              filterFunction = (item) => _.includes(value, item.status);
            }

            if (key === 'bookingType') {
              filterFunction = (item) => _.includes(value, item.serviceType);
            }

            if (key === 'service') {
              filterFunction = (item) => _.includes(value, item.serviceId);
            }

            if (key === 'bookingDateRange') {
              filterFunction = (item) => {
                return item.startDate > value[0] && item.startDate < value[1];
              };
            }

            if (key === 'customerName') {
              filterFunction = (item) => _.includes(value, item.bookerUserId);
            }

            return filterFunction;
          })
          .value();

        let list = rootState.bookingsStore.bookingsList;

        for (let filter of filters) {
          list = list.filter(filter);
        }

        dispatch.bookingsStore.setFilteredBookingList(list);
        dispatch.bookingsStore.setBookingsFilter(payload);
      }
    },

    async doFetchDashboardData(payload, state) {
      try {
        const url = `/api/portal/service-provider/view/dashboard`;
        const result = await apiClient.get(url);
        if (!_.isEmpty(result)) {
          let dashboardData = result.data;

          let pendingList = _.filter(dashboardData.bookingList, (booking) => booking.status === 'PENDING');
          let acceptedList = _.filter(dashboardData.bookingList, (booking) => booking.status === 'ACCEPTED');
          let completedList = _.filter(dashboardData.bookingList, (booking) => booking.status === 'COMPLETED');
          let restList = _.filter(dashboardData.bookingList, (booking) => booking.status !== 'PENDING');

          pendingList = _.orderBy(pendingList, ['startDateTime', 'lastName'], ['desc', 'asc']);
          restList = _.orderBy(restList, ['startDateTime', 'createdOn'], ['desc', 'desc']);
          acceptedList = _.orderBy(acceptedList, ['startDateTime', 'createdOn'], ['desc', 'desc']);
          completedList = _.orderBy(completedList, ['startDateTime', 'createdOn'], ['desc', 'desc']);

          dashboardData.bookingList = pendingList.concat(acceptedList).concat(completedList);
          dashboardData.pendingList = pendingList;
          dashboardData.completedList = completedList;
          dashboardData.acceptedList = acceptedList;

          dispatch.bookingsStore.setDashboardData(dashboardData);
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateBookingBillingItems(payload, rootState) {
      const endPoint = `/api/portal/bookings/billing`;
      const request = { bookingId: rootState.bookingsStore.selectedBookingItem.bookingId, lineItems: payload.list };
      try {
        let result = await apiClient.put(endPoint, request);

        dispatch.bookingsStore.setBillingLineItems(payload);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doUpdateBookingCancelBooking(payload, rootState) {
      let booking = rootState.bookingsStore.selectedBookingItem;
      const endpoint = `/api/portal/bookings/${booking.bookingId}/cancel`;
      try {
        let result = await apiClient.put(endpoint, payload);
        dispatch.bookingsStore.setSelectedBookingItem({
          ...booking,
          ...result.data,
          cancellationReason: payload.reason,
          cancellationCode: payload.cancellationReason,
          shiftSlotStatus: payload.shiftSlot.isPaidShift
            ? ShiftSlotStatus.CANCELLED_PAID
            : ShiftSlotStatus.CANCELLED_UNPAID,
        });
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doUpdateTeamMemberShiftHoursForCancelledBooking(payload, rootState) {
      let booking = rootState.bookingsStore.selectedBookingItem;
      try {
        dispatch.bookingsStore.setSelectedBookingItem({
          ...booking,
          portalCheckedInDateTime: payload.portalCheckedInDateTime,
          portalCheckedOutDateTime: payload.portalCheckedOutDateTime,
        });
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doUnchargeCustomerCancelledBooking(payload, rootState) {
      let booking = rootState.bookingsStore.selectedBookingItem;
      const endpoint = `/api/portal/bookings/${booking.bookingId}/change-to-customer-cancel/uncharge`;
      try {
        let result = await apiClient.put(endpoint, payload);
        dispatch.bookingsStore.setSelectedBookingItem({
          ...booking,
          ...result.data,
        });
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doChargeCustomerCancelledBooking(payload, rootState) {
      let booking = rootState.bookingsStore.selectedBookingItem;
      const endpoint = `/api/portal/bookings/${booking.bookingId}/change-to-customer-cancel/charge`;
      try {
        let result = await apiClient.put(endpoint, payload);
        dispatch.bookingsStore.setSelectedBookingItem({
          ...booking,
          ...result.data,
        });
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doChangeToCustomerCancelled(payload, rootState) {
      let booking = rootState.bookingsStore.selectedBookingItem;
      const endpoint = `/api/portal/bookings/${booking.bookingId}/change-to-customer-cancel`;
      try {
        let result = await apiClient.put(endpoint, payload);
        dispatch.bookingsStore.setSelectedBookingItem({
          ...booking,
          ...result.data,
        });
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doChangeToBusinessCancelled(payload, rootState) {
      let booking = rootState.bookingsStore.selectedBookingItem;
      const endpoint = `/api/portal/bookings/${booking.bookingId}/change-to-business-cancel`;
      try {
        let result = await apiClient.put(endpoint, payload);
        dispatch.bookingsStore.setSelectedBookingItem({
          ...booking,
          ...result.data,
        });
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doUncancelBooking(payload, rootState) {
      let booking = rootState.bookingsStore.selectedBookingItem;
      const endpoint = `/api/portal/bookings/${booking.bookingId}/uncancel`;
      try {
        let result = await apiClient.post(endpoint, payload);
        if (result.data.shiftSlotStatus === ShiftSlotStatus.UNASSIGNED) {
          dispatch.bookingsStore.setSelectedBookingItem({
            ...booking,
            ...result.data,
            shiftSlotStatus: ShiftSlotStatus.UNASSIGNED,
            workerId: null,
            workerUserId: null,
            workerFirstName: null,
            workerLastName: null,
            workerAttachmentPath: null,
            workerAttachmentUrl: null,
            cancellationReason: null,
            cancellationCode: null,
          });
        } else {
          dispatch.bookingsStore.setSelectedBookingItem({
            ...booking,
            ...result.data,
            shiftSlotStatus: ShiftSlotStatus.PENDING,
          });
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doFetchUncancelConflicts(payload, rootState) {
      let booking = rootState.bookingsStore.selectedBookingItem;
      const endpoint = `/api/portal/bookings/${booking.bookingId}/worker-conflict`;
      try {
        const result = await apiClient.post(endpoint, payload);
        return result.data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doFetchNumberOfBookingsForRecurrence(payload, rootState) {
      let booking = rootState.bookingsStore.selectedBookingItem;
      const endpoint = `/api/portal/bookings/${booking.bookingId}/recurring-bookings`;
      try {
        let result = await apiClient.post(endpoint, payload);
        return result.data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doFetchServiceRoster(payload, rootState) {
      const endpoint = `api/portal/bookings/workers`;
      try {
        let result = await apiClient.post(endpoint, payload);
        if (result.data) {
          return result.data;
        } else return [];
      } catch (e) {
        throw e;
      }
    },

    doApplySupportWorkerFilter(payload, rootState) {
      const { name } = payload;
      const oldList = rootState.bookingsStore.selectedServiceRoster;
      const newList = _.filter(oldList, (worker) => {
        return (
          worker.firstName.toLowerCase().includes(name) ||
          worker.lastName.toLowerCase().includes(name.toLowerCase()) ||
          (worker.firstName + ' ' + worker.lastName).toLowerCase().includes(name.toLowerCase())
        );
      });
      dispatch.bookingsStore.setFilteredServiceRoster(newList);
    },

    doClearSupportWorkerFilter(payload, rootState) {
      const oldList = rootState.bookingsStore.selectedServiceRoster;
      dispatch.bookingsStore.setFilteredServiceRoster(oldList);
    },

    // Approve b
    async doApproveBooking(payload, rootState) {
      const selectedBookingItem = rootState.bookingsStore.selectedBookingItem;
      const endPoint = `/api/portal/billing/approve`;
      const request = {
        bookingIds: [selectedBookingItem.bookingId],
      };
      try {
        await apiClient.put(endPoint, request);
        selectedBookingItem.paymentStatus = PaymentStatus.READY_FOR_BILLING;
        dispatch.bookingsStore.setSelectedBookingItem(selectedBookingItem);
      } catch (e) {
        throw e;
      }
    },

    async doSendToFinance(payload, rootState) {
      const selectedBookingItem = _.cloneDeep(rootState.bookingsStore.selectedBookingItem);
      const endPoint = `/api/portal/billing/send-to-finance`;
      const request = {
        bookingIds: [selectedBookingItem.bookingId],
      };

      try {
        const result = await apiClient.put(endPoint, request);
        if (!_.isEmpty(result.data)) {
          selectedBookingItem.paymentStatus = PaymentStatus.SEND_TO_FINANCE;

          selectedBookingItem.billingLineItems = _.map(selectedBookingItem.billingLineItems, (lineItem) => {
            if (
              lineItem.paymentStatus === PaymentStatus.INITIAL ||
              lineItem.paymentStatus === PaymentStatus.REQUIRES_APPROVAL
            ) {
              lineItem.paymentStatus = PaymentStatus.SEND_TO_FINANCE;
            }
            return lineItem;
          });

          const currentBookingHistories = result.data.bookingHistories[selectedBookingItem.bookingId];
          selectedBookingItem.bookingHistories = currentBookingHistories;
          dispatch.bookingsStore.setSelectedBookingItem(selectedBookingItem);
        }

        events.trackApproveBooking({
          bookingId: selectedBookingItem.bookingId,
          paymentStatus: PaymentStatus.SEND_TO_FINANCE,
          bookingReferenceId: selectedBookingItem.bookingReferenceId,
        });
      } catch (e) {
        throw e;
      }
    },

    async doConfirmShiftForWorker(payload, rootState) {
      const selectedBookingItem = _.cloneDeep(rootState.bookingsStore.selectedBookingItem);
      const endPoint = `/api/portal/bookings/${selectedBookingItem.bookingId}/individual/confirm-shift`;
      const request = {
        bookingId: selectedBookingItem.bookingId,
        isRemovePendingShiftSlots: payload.isRemovePendingShiftSlots,
      };
      try {
        const result = await apiClient.put(endPoint, request);
        selectedBookingItem.shiftSlotStatus = ShiftSlotStatus.CONFIRMED;
        selectedBookingItem.bookingHistories = result.data.bookingHistories;
        dispatch.bookingsStore.setSelectedBookingItem(selectedBookingItem);
      } catch (e) {
        throw e;
      }
    },

    async doConfirmRecurringShiftForWorker(payload, rootState) {
      const selectedBookingItem = _.cloneDeep(rootState.bookingsStore.selectedBookingItem);
      const endPoint = `/api/portal/bookings/${selectedBookingItem.bookingId}/individual/confirm-shift`;
      const request = {
        bookingId: selectedBookingItem.bookingId,
        isRecurring: true,
        editRecurringMode: payload.editRecurringMode,
        bookingRequestId: payload.bookingRequestId,
        isRemovePendingShiftSlots: payload.isRemovePendingShiftSlots,
        ignoreShiftClashShiftIds: payload.ignoreShiftClashShiftIds,
        ignoreOutsideAvailabilityShiftIds: payload.ignoreOutsideAvailabilityShiftIds,
      };
      try {
        const result = await apiClient.put(endPoint, request);
        selectedBookingItem.shiftSlotStatus = result.data.shiftSlotStatus
          ? result.data.shiftSlotStatus
          : ShiftSlotStatus.CONFIRMED;
        selectedBookingItem.bookingHistories = result.data.bookingHistories;
        dispatch.bookingsStore.setSelectedBookingItem(selectedBookingItem);
        return {
          confirmedShiftsCount: _.get(result, 'data.confirmedShiftsCount', 1),
          currentShiftStatus: selectedBookingItem.shiftSlotStatus,
        };
      } catch (e) {
        throw e;
      }
    },

    async doWaiveWholeBooking(payload, rootState) {
      const selectedBookingItem = _.cloneDeep(rootState.bookingsStore.selectedBookingItem);
      const endPoint = `/api/portal/billing/waive-booking`;
      const request = {
        bookingId: payload.bookingId,
        waivedReason: payload.waivedReason,
      };
      try {
        await apiClient.put(endPoint, request);
        _.forEach(selectedBookingItem.billingLineItems, (item) => {
          item.paymentStatus = PaymentStatus.WAIVED;
        });

        selectedBookingItem.paymentStatus = PaymentStatus.WAIVED;

        dispatch.bookingsStore.setSelectedBookingItem(selectedBookingItem);
      } catch (e) {
        throw e;
      }
    },

    async doUpdateBookingDates(payload, rootState) {
      const selectedBookingItem: IBillingBooking = { ...rootState.bookingsStore.selectedBookingItem };

      const endPoint = `/api/portal/bookings/${selectedBookingItem.bookingId}/start-end-time`;
      const request = {
        startDateTime: payload.startDateTime,
        endDateTime: payload.endDateTime,
        editRecurringMode: payload.editRecurringMode,
        removeWorkerAndSave: payload.removeWorkerAndSave,
        numberOfBookings: payload.numberOfBookings,
        isRecurring: payload.isRecurring,
        bookingRequestId: payload.bookingRequestId,
        timezone: payload.timezone,
        isRemovePendingShiftSlots: payload.isRemovePendingShiftSlots,
        shiftsSelectedToBeKept: payload.shiftsSelectedToBeKept ? payload.shiftsSelectedToBeKept : null,
      };
      try {
        let result = await apiClient.put(endPoint, payload);
        if (result.data) {
          if (!result.data.conflict) {
            dispatch.bookingsStore.setSelectedBookingItem({ ...selectedBookingItem, ...result.data });
          }
          return result.data;
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doUpdateBookingLocation(payload, rootState) {
      const selectedBookingItem = { ...rootState.bookingsStore.selectedBookingItem };

      const endPoint = `/api/portal/bookings/${payload.bookingId}/address`;
      try {
        let result = await apiClient.put(endPoint, payload);
        if (result.data) {
          selectedBookingItem.address = payload.address;
          selectedBookingItem.bookingHistories = result.data.bookingHistories;
          selectedBookingItem.billingLineItems = result.data.billingLineItems;
          dispatch.bookingsStore.setSelectedBookingItem(selectedBookingItem);
          return result.data;
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doUpdateBookingDatesAndRemoveWorker(payload, rootState) {
      const selectedBookingItem: IBillingBooking = { ...rootState.bookingsStore.selectedBookingItem };

      const endPoint = `/api/portal/bookings/${selectedBookingItem.bookingId}/start-end-time`;
      const request = {
        bookingId: selectedBookingItem.bookingId,
        startDateTime: payload.startDateTime,
        endDateTime: payload.endDateTime,
      };
      try {
        let result = await apiClient.put(endPoint, request);
        if (result.data) {
          if (!result.data.conflict) {
            selectedBookingItem.startDateTime = payload.startDateTime;
            selectedBookingItem.endDateTime = payload.endDateTime;
            selectedBookingItem.workerFirstName = null;
            selectedBookingItem.workerLastName = null;
            selectedBookingItem.workerUserId = null;
            selectedBookingItem.workerAttachmentPath = null;
            selectedBookingItem.workerAttachmentUrl = null;
            dispatch.bookingsStore.setSelectedBookingItem(selectedBookingItem);
          }
          return result.data;
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doUpdateBookingWorkerCheckInDateTime(payload, rootState) {
      const selectedBookingItem: IBillingBooking = { ...rootState.bookingsStore.selectedBookingItem };

      const endPoint = '/api/portal/bookings/checkin-time';
      const request = {
        bookingId: selectedBookingItem.bookingId,
        checkInDateTime: payload.checkInDateTime,
      };
      try {
        let result = await apiClient.put(endPoint, request);
        dispatch.bookingsStore.setSelectedBookingItem({ ...selectedBookingItem, ...result.data });

        return true;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doUpdateBookingWorkerCheckInOutDateTime(payload, rootState) {
      const selectedBookingItem: IBillingBooking = { ...rootState.bookingsStore.selectedBookingItem };

      const endPoint = '/api/portal/bookings/checkin-checkout-time';
      const request = {
        bookingId: selectedBookingItem.bookingId,
        checkInDateTime: payload.checkInDateTime,
        checkOutDateTime: payload.checkOutDateTime,
        isTravel: selectedBookingItem.isTravel,
        travelDistance: Number(selectedBookingItem.travelDistance),
      };
      try {
        let result = await apiClient.put(endPoint, request);
        dispatch.bookingsStore.setSelectedBookingItem({ ...selectedBookingItem, ...result.data });
        return true;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    // Accept bookings
    async doBatchAcceptBookings(payload, rootState) {
      const { action, status } = payload;

      const bookingList = rootState.bookingsStore.selectedBookings;
      const endPoint = `/api/portal/bookings/individual/list/accept`;

      const selectedBookings = _.chain(bookingList)
        .filter((booking) => booking.selected === true && booking.status === status)
        .map((booking) => ({ bookingId: booking.bookingId }))
        .value();

      const request = { list: [...selectedBookings] };

      try {
        dispatch.bookingsStore.setAwaitingBookActions(true);
        const response = await apiClient.put(endPoint, request);
        dispatch.bookingsStore.setAwaitingBookActions(false);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    // Accept & confirm bookings
    async doBatchAcceptConfirmBookings(payload, rootState) {
      const { action, status } = payload;

      const bookingList = rootState.bookingsStore.selectedBookings;
      const endPoint = `/api/portal/bookings/individual/list/accept-and-confirm`;

      const selectedBookings = _.chain(bookingList)
        .filter((booking) => booking.selected === true && booking.status === status)
        .map((booking) => ({ bookingId: booking.bookingId }))
        .value();

      const request = { list: [...selectedBookings] };

      try {
        dispatch.bookingsStore.setAwaitingBookActions(true);
        const response = await apiClient.put(endPoint, request);
        dispatch.bookingsStore.setAwaitingBookActions(false);
      } catch (e) {
        throw e;
      }
    },

    // Confirm bookings
    async doBatchConfirmBookings(payload, rootState) {
      const { action, status } = payload;

      const bookingList = rootState.bookingsStore.selectedBookings;
      const endPoint = `/api/portal/bookings/individual/list/confirm`;

      const selectedBookings = _.chain(bookingList)
        .filter((booking) => booking.selected === true && booking.status === status)
        .map((booking) => ({ bookingId: booking.bookingId }))
        .value();

      const request = { list: [...selectedBookings] };

      try {
        dispatch.bookingsStore.setAwaitingBookActions(true);
        const response = await apiClient.put(endPoint, request);
        dispatch.bookingsStore.setAwaitingBookActions(false);
      } catch (e) {
        throw e;
      }
    },

    // Reject bookings
    async doBatchRejectBookings(payload, rootState) {
      const { action, status } = payload;

      const bookingList = rootState.bookingsStore.selectedBookings;
      const endPoint = `/api/portal/bookings/individual/list/reject`;

      const selectedBookings = _.chain(bookingList)
        .filter((booking) => booking.selected === true && booking.status === status)
        .map((booking) => ({ bookingId: booking.bookingId }))
        .value();

      const request = { list: [...selectedBookings] };

      try {
        dispatch.bookingsStore.setAwaitingBookActions(true);
        const response = await apiClient.put(endPoint, request);
        dispatch.bookingsStore.setAwaitingBookActions(false);
      } catch (e) {
        throw e;
      }
    },

    // Approve bookings
    async doBatchApproveBookings(payload, rootState) {
      const { action, status } = payload;

      const bookingList = rootState.bookingsStore.selectedBookings;
      const endPoint = `/api/portal/billing/approve`;

      const selectedBookings = _.chain(bookingList)
        .filter(
          (booking) =>
            booking.selected === true && booking.status === status && booking.paymentStatus === 'SEND_TO_FINANCE',
        )
        .map((booking) => booking.bookingId)
        .value();

      const request = { bookingIds: [...selectedBookings] };

      try {
        dispatch.bookingsStore.setAwaitingBookActions(true);
        const response = await apiClient.put(endPoint, request);
        dispatch.bookingsStore.setAwaitingBookActions(false);
      } catch (e) {
        throw e;
      }
    },

    // Send to finance bookings
    async doBatchSendToFinanceBookings(payload, rootState) {
      const { action, status } = payload;

      const bookingList = rootState.bookingsStore.selectedBookings;
      const endPoint = `/api/portal/billing/send-to-finance`;

      const selectedBookings = _.chain(bookingList)
        .filter(
          (booking) =>
            booking.selected === true &&
            booking.status === status &&
            booking.paymentStatus === PaymentStatus.REQUIRES_APPROVAL,
        )
        .map((booking) => booking.bookingId)
        .value();

      const request = { bookingIds: [...selectedBookings] };

      try {
        dispatch.bookingsStore.setAwaitingBookActions(true);
        const response = await apiClient.put(endPoint, request);
        dispatch.bookingsStore.setAwaitingBookActions(false);

        return { response: response.data, bookingList: request.bookingIds };
      } catch (e) {
        throw e;
      }
    },

    async doUpdateBookingCancellationReason(payload, rootStore) {
      const selectedBookingItem = rootStore.bookingsStore.selectedBookingItem;
      const endPoint = '/api/portal/bookings/cancellation-reason';
      const { cancellationReason, reason } = payload;
      const request = {
        bookingId: selectedBookingItem.bookingId,
        cancellationReason,
        reason,
      };

      try {
        const result = await apiClient.put(endPoint, request);
        selectedBookingItem.cancellationCode = cancellationReason;
        selectedBookingItem.cancellationReason = reason;
        selectedBookingItem.bookingHistories = result.data.bookingHistories;
        dispatch.bookingsStore.setSelectedBookingItem(selectedBookingItem);
      } catch (e) {
        throw e;
      }
    },

    async doUpdateSleepOverShift(
      payload: {
        bookingId: string;
        bookingRequestId: string;
        timezone: string;
        numberOfBookings: number;
        isRecurring: boolean;
        editRecurringMode: number;
        sleepoverType: string;
        sleepoverTimeSlots: ISleepoverTimeSlot[];
      },
      rootStore,
    ) {
      const selectedBookingItem: IBillingBooking = { ...rootStore.bookingsStore.selectedBookingItem };
      const endPoint = `/api/portal/bookings/${payload.bookingId}/sleepover-time-slots`;
      const request = {
        bookingId: payload.bookingId,
        bookingRequestId: payload.bookingRequestId,
        timezone: payload.timezone,
        numberOfBookings: payload.numberOfBookings,
        isRecurring: payload.isRecurring,
        editRecurringMode: payload.editRecurringMode,
        sleepoverType: payload.sleepoverType,
        sleepoverTimeSlots: payload.sleepoverTimeSlots,
      };

      try {
        let result = await apiClient.put(endPoint, request);
        if (result.data) {
          dispatch.bookingsStore.setSelectedBookingItem({ ...selectedBookingItem, ...result.data });
          return result.data;
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    async doDeleteDisturbance(payload, rootStore) {
      const { disturbanceId } = payload;
      try {
        const endpoint = `api/portal/bookings/${rootStore.bookingsStore.selectedBookingItem.bookingId}/delete-disturbance/${disturbanceId}`;
        const result = await apiClient.delete(endpoint);
        if (result) {
          await dispatch.bookingsStore.doFetchSingleBooking({
            bookingId: rootStore.bookingsStore.selectedBookingItem.bookingId,
          });
          return result;
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doCheckRecurringPattern(payload, rootState) {
      const endpoint = `api/portal/bookings/individual/recurring-check`;

      try {
        return await apiClient.post(endpoint, payload);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doCheckServiceAvailability(payload, rootState) {
      const endpoint = `api/portal/bookings/individual/service-availability-check`;

      try {
        const result = await apiClient.post(endpoint, payload);
        return result.data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doCheckEditTimes(payload, rootState) {
      const endpoint = `api/portal/bookings/${payload.bookingId}/edit-time-check`;

      try {
        return await apiClient.post(endpoint, payload);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doCheckEditLocationConflict(payload, rootState) {
      const endpoint = `api/portal/bookings/${payload.bookingId}/address/conflicts`;

      try {
        return await apiClient.post(endpoint, payload);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doCheckWorker(payload, rootState) {
      const endpoint = `api/portal/bookings/individual/worker-check`;

      try {
        return await apiClient.post(endpoint, payload);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doExtendRecurrence(payload, rootState) {
      const endpoint = `api/portal/bookings/${payload.bookingId}/recurring/extend`;
      try {
        const result = await apiClient.put(endpoint, payload);
        dispatch.bookingsStore.updateExtendedRecurrence({ ...payload, newShiftSlots: result.data });
        return true;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doCheckAssignWorker(payload, rootState) {
      const endpoint = `api/portal/bookings/${payload.bookingId}/worker-conflict-check`;

      try {
        return await apiClient.post(endpoint, payload);
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchRecurringBookingsList(payload, rootState) {
      const endpoint = `api/portal/bookings/${payload.bookingId}/recurring/list`;
      try {
        const result = await apiClient.post(endpoint, payload);
        dispatch.bookingsStore.setRecurringBookingList(result.data && result.data.bookingInRecurrenceList);
        dispatch.bookingsStore.setBookingRecurringPattern(result.data && result.data.recurringPattern);
        return result.data;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doEditNote(payload, rootState) {
      const endpoint = `api/portal/bookings/${payload.bookingId}/notes/${payload.noteId}`;
      try {
        let result = await apiClient.put(endpoint, payload);
        return result.data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doDeleteBookingNote(payload, rootState) {
      const endpoint = `api/portal/bookings/${payload.bookingId}/notes/${payload.noteId}`;
      try {
        let result = await apiClient.delete(endpoint);

        await dispatch.bookingsStore.doFetchSingleBooking({
          bookingId: payload.bookingId,
        });
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doEditBookingInstruction(payload, rootState) {
      const endpoint = `api/portal/bookings/${payload.bookingId}/instructions`;
      try {
        let result = await apiClient.put(endpoint, payload);

        await dispatch.bookingsStore.doFetchSingleBooking({
          bookingId: payload.bookingId,
        });
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doUpdateBookingTravelClaims(payload, rootState) {
      const endpoint = `/api/portal/bookings/${payload.bookingId}/travel-details`;

      try {
        const result = await apiClient.put(endpoint, payload);
        dispatch.bookingsStore.setSelectedBookingItem({
          ...rootState.bookingsStore.selectedBookingItem,
          ...result.data,
        });
        return true;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doExportBookingNote(payload, rootState) {
      const endpoint = `api/portal/bookings/${payload.bookingId}/notes/export`;
      try {
        let result = await apiClient.post(endpoint, payload);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doCheckServiceAgreementDate(payload, rootState) {
      const endpoint = `api/portal/customers/${payload.customerUserId}/service-agreements/check-datetime`;
      try {
        let result = await apiClient.post(endpoint, payload);
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doIgnoreBookingWarning(payload, rootState) {
      const endpoint = `api/portal/bookings/${payload.bookingId}/warnings`;
      const requestBody = {
        bookingErrors: [payload.bookingErrorType],
      };
      try {
        let result = await apiClient.delete(endpoint, requestBody);

        const newBooking = _.clone(rootState.bookingsStore.selectedBookingItem);
        // @ts-ignore
        _.remove(newBooking.bookingErrors, (error) => error.bookingErrorType === payload.bookingErrorType);

        dispatch.bookingsStore.setSelectedBookingItem({ ...newBooking });
      } catch (e) {
        throw e;
      }
    },

    async doRevokeBookingApproval(payload, rootState) {
      const endpoint = `api/portal/bookings/${rootState.bookingsStore.selectedBookingItem.bookingId}/revoke-approval`;
      try {
        const result = await apiClient.put(endpoint);

        if (result.data) {
          const selectedBookingItem = _.cloneDeep(rootState.bookingsStore.selectedBookingItem);

          const newBookingItem = { ...selectedBookingItem, ...result.data };

          dispatch.bookingsStore.setSelectedBookingItem(newBookingItem);
        }
      } catch (e) {
        throw e;
      }
    },

    async doPayTeamMemberForCancelledShift(payload, rootState) {
      const { selectedBookingItem } = rootState.bookingsStore;
      const endpoint = `api/portal/bookings/${selectedBookingItem.bookingId}/shift-slot-hours`;
      /* expected payload shiftSlots:[{supportWorkerId, isPaidShift, shiftHours }] */
      try {
        const result = await apiClient.put(endpoint, payload);
        /**TODO:
         * Currently we are performing the shiftSlot changes for a single worker but for FUTURE, we
         * will need to perform it for a group of workers. In this case, the dispatch logic below
         * should be modified.
         */
        let shiftSlotStatus = payload.shiftSlots[0].isPaidShift
          ? ShiftSlotStatus.CANCELLED_PAID
          : ShiftSlotStatus.CANCELLED_UNPAID;
        const newSelectedBookingItem = {
          ...rootState.bookingsStore.selectedBookingItem,
          shiftSlotStatus,
          ...result.data,
        };
        dispatch.bookingsStore.setSelectedBookingItem(newSelectedBookingItem);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doApproveTeamMemberPayHoursForCancelledBooking(payload, rootState) {
      const { selectedBookingItem } = rootState.bookingsStore;
      const endpoint = `api/portal/bookings/${selectedBookingItem.bookingId}/shift-slot-hours/approve`;
      try {
        await apiClient.post(endpoint, payload);
        const newSelectedBookingItemState = {
          ...selectedBookingItem,
          shiftSlotStatus: ShiftSlotStatus.SENT_TO_FINANCE,
        };
        dispatch.bookingsStore.setSelectedBookingItem(newSelectedBookingItemState);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doRevokeTeamMemberPayHoursForCancelledBooking(payload, rootState) {
      const { selectedBookingItem } = rootState.bookingsStore;
      const endpoint = `api/portal/bookings/${selectedBookingItem.bookingId}/shift-slot-hours/revoke-approval`;
      try {
        await apiClient.post(endpoint, payload);
        const newSelectedBookingItemState = { ...selectedBookingItem, shiftSlotStatus: ShiftSlotStatus.CANCELLED_PAID };
        dispatch.bookingsStore.setSelectedBookingItem(newSelectedBookingItemState);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doFetchBookingPaymentDetail(payload, rootState) {
      const endpoint = `api/portal/bookings/selected-billing-line-items`;
      try {
        const data = {
          ...payload,
          address: {
            ...payload.address,
            geoLat: payload.address ? Number(payload.address.geoLat) : 0,
            geoLng: payload.address ? Number(payload.address.geoLng) : 0,
          },
        };
        let result = await apiClient.post(endpoint, data);
        dispatch.bookingsStore.setCustomerBookingPaymentDetail(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doFetchBookingSuggestionPaymentDetail(payload, rootState) {
      const endpoint = `api/portal/bookings/suggest-billing-list`;
      try {
        const data = {
          ...payload,
          address: {
            ...payload.address,
            geoLat: payload.address ? Number(payload.address.geoLat) : 0,
            geoLng: payload.address ? Number(payload.address.geoLng) : 0,
          },
        };
        let result = await apiClient.post(endpoint, data);
        dispatch.bookingsStore.setCustomerBookingSuggestionPaymentDetail(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doUpdateBookingSelectedPaymentDetail(payload, rootState) {
      const endpoint = `api/portal/bookings/${payload.bookingId}/selected-billing-line-items`;
      try {
        let result = await apiClient.put(endpoint, payload);
        dispatch.bookingsStore.setSelectedBookingItem({
          ...rootState.bookingsStore.selectedBookingItem,
          ...result.data,
        });
      } catch (e) {
        throw e;
      }
    },

    async doChangePreferredPaymentSource(payload, rootState) {
      const endpoint = `api/portal/bookings/${payload.bookingId}/preferred-payment-method`;
      try {
        let result = await apiClient.put(endpoint, payload);
        dispatch.bookingsStore.setSelectedBookingItem({
          ...rootState.bookingsStore.selectedBookingItem,
          paymentSourceType: payload.paymentSourceType,
          ...result.data,
        });
      } catch (e) {
        throw e;
      }
    },

    async doArchiveBooking(payload, rootState) {
      const endpoint = `api/portal/bookings/${payload.bookingId}/archive`;
      try {
        let result = await apiClient.put(endpoint, payload);
        dispatch.bookingsStore.setSelectedBookingItem({
          ...rootState.bookingsStore.selectedBookingItem,
          ...result.data,
        });
      } catch (e) {
        throw e;
      }
    },

    async doRevertBookingToConfirmed(payload, rootState) {
      const endpoint = `api/portal/bookings/${payload.bookingId}/revert-to-confirmed`;
      try {
        let result = await apiClient.put(endpoint, payload);
        dispatch.bookingsStore.setSelectedBookingItem({
          ...rootState.bookingsStore.selectedBookingItem,
          ...result.data,
        });
      } catch (e) {
        throw e;
      }
    },

    async doAddBookingDocument(payload, rootState) {
      try {
        const portalUser = rootStore.getState().authStore.portalUser;
        const request = { ...payload, addedBy: portalUser.userId };
        const result = await apiClient.post(`/api/portal/bookings/${payload.userId}/document`, request);
        dispatch.bookingsStore.addBookingDocument({
          ...payload,
          documentId: result.data.documentId,
          firstName: portalUser.firstName,
          lastName: portalUser.lastName,
          visibleType: request.visibleType,
        });
        return result.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doEditBookingDocument(payload, rootState) {
      try {
        const result = await apiClient.put(
          `/api/portal/bookings/${payload.bookingId}/document/${payload.documentId}`,
          payload,
        );
        dispatch.bookingsStore.editBookingDocument({
          ...payload,
          documentId: payload.documentId,
        });
        return result.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doRemoveBookingDocument(payload, rootState) {
      try {
        const result = await apiClient.delete(
          `/api/portal/bookings/${payload.bookingId}/document/${payload.documentId}`,
        );
        dispatch.bookingsStore.removeBookingDocument({
          documentId: payload.documentId,
          noteId: result && result.data && result.data.noteId,
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doCreateActivityRecord(payload, rootState) {
      const endpoint = `api/portal/bookings/activity-record`;
      try {
        let result = await apiClient.post(endpoint, payload);
        // Appcues
        const memberId = payload?.supportWorkerId;
        if (memberId) events.trackAssignMemberToBooking({ memberId });

        events.trackCreateActivityRecord({
          supportWorkerId: payload.supportWorkerId,
          serviceId: payload.serviceId,
          paymentSourceType: payload.paymentSourceType,
          noteContent: payload?.note?.noteContent,
          customerUserId: payload.customerUserId,
        });

        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doEditActivityRecordHours(payload, rootState) {
      const endpoint = `api/portal/bookings/${payload.bookingId}/activity-record-hours`;
      try {
        let result = await apiClient.put(endpoint, payload);
        dispatch.bookingsStore.setSelectedBookingItem({
          ...rootState.bookingsStore.selectedBookingItem,
          isDuration: payload.isDuration,
          ...result.data,
        });
      } catch (e) {
        throw e;
      }
    },

    async doFetchSuburbs(payload, rootState) {
      const endpoint = `api/portal/bookings/suburbs`;
      try {
        let result = await apiClient.post(endpoint, payload);
        dispatch.bookingsStore.setSuburbs(result.data.suburbsList);
      } catch (e) {
        throw e;
      }
    },

    async doFetchBookingViews(payload, rootState) {
      const endpoint = `api/portal/custom-views/booking/list`;
      const { displayedBookingListingTabs } = rootState.bookingsStore;
      try {
        const response = await apiClient.post(endpoint, payload);
        await dispatch.bookingsStore.setBookingViews(response.data);
        await dispatch.bookingsStore.setDisplayedBookingListingTabs([...displayedBookingListingTabs]);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doAddBookingView({ sharedWith, filterValue, customViewId, ...payload }, rootState) {
      const endpoint = `api/portal/custom-views/booking`;
      const { bookingViews, displayedBookingListingTabs } = rootState.bookingsStore;
      try {
        const sharedWithIds = sharedWith.map((user) => user && user.supportWorkerId);

        payload = {
          ...payload,
          sharedWithIds,
          filterValue: mapBookingViewFilterPayload(filterValue),
        };

        const response = await apiClient.post(endpoint, payload);

        const newTab = {
          ...payload,
          ...response.data,
          sharedWith,
          isOwner: true,
          isPinned: false,
        };
        dispatch.bookingsStore.setBookingViews([...bookingViews, newTab]);
        await dispatch.bookingsStore.setDisplayedBookingListingTabs([...displayedBookingListingTabs, newTab]);
        dispatch.bookingsStore.setBookingListingActiveTab(newTab);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doUpdateBookingView({ sharedWith, filterValue, customViewId, isTogglePinned, ...payload }, rootState) {
      const endpoint = `/api/portal/custom-views/booking/${customViewId}`;
      const { bookingViews, displayedBookingListingTabs } = rootState.bookingsStore;
      try {
        let requestPayload = { ...payload };
        if (isTogglePinned) {
          requestPayload = {
            isPinned: payload.isPinned,
          };
        } else {
          if (sharedWith) {
            const sharedWithIds = sharedWith.map((user) => user && user.supportWorkerId);
            requestPayload = {
              ...payload,
              sharedWithIds,
              filterValue: mapBookingViewFilterPayload(filterValue),
            };
          }
        }

        await apiClient.put(endpoint, requestPayload);
        const newView = {
          ...payload,
          customViewId,
          filterValue,
          sharedWith,
        };
        const newBookingViews = bookingViews.map((view) => {
          return view.customViewId === newView.customViewId ? { ...view, ...newView } : view;
        });

        await dispatch.bookingsStore.setBookingViews(newBookingViews);

        // if current view is displayed, update the displayed view
        const displayedIndex = displayedBookingListingTabs.findIndex(
          (view) => view.customViewId === newView.customViewId,
        );

        if (displayedIndex > -1) {
          displayedBookingListingTabs[displayedIndex] = newView;
          await dispatch.bookingsStore.setDisplayedBookingListingTabs([...displayedBookingListingTabs]);
        }

        dispatch.bookingsStore.setBookingListingActiveTab(newView);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doDuplicateBookingView({ sharedWith, customViewId, ...payload }, rootState) {
      const endpoint = `api/portal/custom-views/booking/${customViewId}/duplicate`;
      const { bookingViews, displayedBookingListingTabs } = rootState.bookingsStore;
      try {
        const sharedWithIds = sharedWith.map((user) => user && user.supportWorkerId);
        payload = {
          ...payload,
          sharedWithIds,
        };

        const response = await apiClient.post(endpoint, payload);

        const { filterValue, ...data } = response.data;

        const newTab = {
          ...payload,
          ...data,
          filterValue,
          sharedWith,
          isOwner: true,
          isPinned: false,
        };
        dispatch.bookingsStore.setBookingViews([...bookingViews, newTab]);
        await dispatch.bookingsStore.setDisplayedBookingListingTabs([...displayedBookingListingTabs, newTab]);
        dispatch.bookingsStore.setBookingListingActiveTab(newTab);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doDeleteBookingView({ customViewId, ...payload }, rootState) {
      const endpoint = `api/portal/custom-views/booking/${customViewId}`;
      const { bookingViews, displayedBookingListingTabs, defaultBookingViews } = rootState.bookingsStore;
      try {
        await apiClient.delete(endpoint);
        const newView = {
          ...payload,
          customViewId,
        };
        const newBookingViews = bookingViews.filter((view) => view.customViewId !== newView.customViewId);

        await dispatch.bookingsStore.setBookingViews(newBookingViews);

        // if current view is displayed, update the displayed view
        const displayedIndex = displayedBookingListingTabs.findIndex(
          (view) => view.customViewId === newView.customViewId,
        );

        if (displayedIndex > -1) {
          displayedBookingListingTabs.splice(displayedIndex, 1);
          await dispatch.bookingsStore.setDisplayedBookingListingTabs([...displayedBookingListingTabs]);
        }

        dispatch.bookingsStore.setBookingListingActiveTab(_.head(defaultBookingViews));
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doBulkCancelBookingsRecurringModal(
      payload: {
        bookingId: string;
        bookingRequestId: string;
        cancellationReason: string;
        editRecurringMode: number;
        isBusinessCancel: boolean;
        isCharge: boolean;
        isCustomerNoShow: boolean;
        shiftSlot: {
          isPaidShift: boolean;
          shiftHours: number;
        };
        cancelBookingIds: string[];
      },
      rootState,
    ) {
      try {
        const { bookingId, ...restPayload } = payload;
        const endPoint = `api/portal/bookings/${bookingId}/cancel`;
        const { data } = await apiClient.put(endPoint, restPayload);
        await this.doFetchSingleBooking({ bookingId: bookingId });
        if (data) return data;
      } catch (error) {
        throw error;
      }
    },

    async doAddBookingForm(payload: IAddBookingFormPayload, rootState: IRootState) {
      try {
        const { bookingId, ...restPayload } = payload;
        const endpoint = `api/portal/bookings/${bookingId}/forms`;
        const { data } = await apiClient.post(endpoint, restPayload);
        await dispatch.bookingsStore.doFetchSingleBooking({ bookingId: payload.bookingId });
        return data;
      } catch (error) {
        throw error;
      }
    },

    async doDeleteBookingForm(payload: { bookingId: string; attendanceFormId: string }, rootState: IRootState) {
      try {
        const { data } = await apiClient.delete(
          `api/portal/bookings/${payload.bookingId}/forms/${payload.attendanceFormId}`,
        );
        await dispatch.bookingsStore.doFetchSingleBooking({ bookingId: payload.bookingId });
        return data;
      } catch (error) {
        throw error;
      }
    },

    async doEditTeamMemberCustomerForm(
      payload: { bookingId: string; attendanceFormId: string; involvedCustomerId: string; involvedMemberIds: string[] },
      rootState: IRootState,
    ) {
      try {
        const { bookingId, attendanceFormId, ...restPayload } = payload;
        const endPoint = `api/portal/bookings/${bookingId}/forms/${attendanceFormId}/customer-members`;
        const { data } = await apiClient.put(endPoint, restPayload);
        await this.doFetchSingleBooking({ bookingId });
        return data;
      } catch (error) {
        throw error;
      }
    },

    async doFetchFormDetailByAttendanceFormId(
      payload: {
        bookingId: string;
        attendanceFormId: string;
      },
      rootState: IRootState,
    ) {
      try {
        const { data } = await apiClient.get(
          `api/portal/bookings/${payload.bookingId}/forms/${payload.attendanceFormId}`,
        );
        if (data) {
          const formattedData = {
            formContent: {
              title: { formTitle: data.formName, formDescription: data.formDescription },
              elements: data.formElements,
            },
            formData: data.formData,
          };
          dispatch.formBuilderStore.setCurrentForm(formattedData.formContent);
          return formattedData;
        }
      } catch (error) {
        throw error;
      }
    },

    async doUpdateBookingForm(
      payload: {
        bookingId: string;
        attendanceFormId: string;
        formData: IElementValue[];
        status: string;
      },
      rootState: IRootState,
    ) {
      try {
        const { bookingId, attendanceFormId, formData, status } = payload;
        const endPoint = `api/portal/bookings/${bookingId}/forms/${attendanceFormId}/form-data`;
        const { data } = await apiClient.put(endPoint, { formData, status });
        return data;
      } catch (error) {
        throw error;
      }
    },

    async doBulkRemoveTeamMemberFromRecurringListing(
      payload: {
        bookingId: string;
        bookingRequestId: string;
        BookingType: string;
        editRecurringMode: number;
        removeWorkersFromBookings: {
          bookingId: string;
          supportWorkerId: string;
        }[];
      },
      rootState,
    ) {
      const { bookingId, ...restPayload } = payload;
      const endpoint = `api/portal/bookings/${payload.bookingRequestId}/individual/assign`;
      try {
        let result = await apiClient.delete(endpoint, restPayload);
        await this.doFetchSingleBooking({ bookingId: bookingId });
        return result;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },

    async doAddBookingDisturbance(payload, rootState) {
      try {
        const request = { ...payload };
        const result = await apiClient.post(`/api/portal/bookings/${payload.bookingId}/create-disturbance`, request);
        if (result.data) {
          dispatch.bookingsStore.doFetchSingleBooking({ bookingId: payload.bookingId });
          return result.data;
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doUpdateBookingDisturbance(payload, rootState) {
      try {
        // const portalUser = rootStore.getState().authStore.portalUser;
        const request = { ...payload };
        const result = await apiClient.put(
          `/api/portal/bookings/${payload.bookingId}/update-disturbance/${payload.disturbanceId}`,
          request,
        );
        if (result.data) {
          dispatch.bookingsStore.doFetchSingleBooking({ bookingId: payload.bookingId });
          return result.data;
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
  }),
};

export default bookingsStore;
