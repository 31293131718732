export enum BookingStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  CONFIRMED = 'CONFIRMED',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
  CUSTOMER_CANCELLED = 'CUSTOMER_CANCELLED',
  CUSTOMER_CANCELLED_WITH_FEE = 'CUSTOMER_CANCELLED_WITH_FEE',
  CUSTOMER_CANCELLED_WITHOUT_FEE = 'CUSTOMER_CANCELLED_WITHOUT_FEE',
  BUSINESS_CANCELLED = 'BUSINESS_CANCELLED',
  REJECTED = 'REJECTED',
  ARCHIVED = 'ARCHIVED',
}

export enum BookingStatusString {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  CONFIRMED = 'CONFIRMED',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
  CUSTOMER_CANCELLED = 'CUSTOMER CANCELLED',
  CUSTOMER_CANCELLED_WITH_FEE = 'CUSTOMER CANCELLED WITH FEE',
  CUSTOMER_CANCELLED_WITHOUT_FEE = 'CUSTOMER CANCELLED WITHOUT FEE',
  BUSINESS_CANCELLED = 'BUSINESS CANCELLED',
  REJECTED = 'REJECTED',
  BILLED = 'BILLED',
  ARCHIVED = 'ARCHIVED',
}

export enum PaymentMethods {
  NDIA = 'NDIA',
  PLAN = 'PLAN',
  SELF = 'SELF',
}

export enum PaymentWarningsType {
  NDIA = 'NDIA',
  PLAN = 'PLAN',
  SELF = 'SELF',
  NO_SERVICE_AGREEMENT = 'NO_SERVICE_AGREEMENT',
}

export enum PaymentMethodsLabel {
  NDIA = 'NDIA',
  PLAN = 'Plan Management',
  SELF = 'Self Management',
}

export enum PaymentStatus {
  INITIAL = 'INITIAL',
  REQUIRES_APPROVAL = 'REQUIRES_APPROVAL',
  READY_FOR_BILLING = 'READY_FOR_BILLING',
  SEND_TO_FINANCE = 'SEND_TO_FINANCE',
  PROCESSED = 'PROCESSED',
  BILLING_FAILED = 'BILLING_FAILED',
  WAIVED = 'WAIVED',
  NO_CHARGE = 'NO_CHARGE',
  REJECTED = 'REJECTED',
  PAID = 'PAID',
}

export enum PaymentStatusLabel {
  INITIAL = '-',
  REQUIRES_APPROVAL = 'Require approval',
  READY_FOR_BILLING = 'Ready for billing',
  SEND_TO_FINANCE = 'Sent to finance',
  PROCESSED = 'Processed',
  BILLING_FAILED = 'Billing failed',
  WAIVED = 'Waived',
  NO_CHARGE = 'No charge',
  REJECTED = 'Rejected',
}

export enum PublishShiftApplicationStatus {
  APPLIED = 'APPLIED',
  UNSUCCESSFUL = 'UNSUCCESSFUL',
}

export enum PublishShiftApplicationAvailability {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  OUTSIDE_GENERAL_AVAILABILITY = 'OUTSIDE_GENERAL_AVAILABILITY',
  UNAVAILABLE_CAN_BE_OVERRIDDEN = 'UNAVAILABLE_CAN_BE_OVERRIDDEN',
  UNAVAILABLE = 'UNAVAILABLE',
  SHIFT_CLASH = 'SHIFT_CLASH',
}

export enum WorkerFilterStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  UNASSIGNED = 'UNASSIGNED',
  PUBLISHING = 'PUBLISHING',
}

export enum ShiftSlotStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  UNASSIGNED = 'UNASSIGNED',
  CHECKED_IN = 'CHECKED_IN',
  CHECKED_OUT = 'CHECKED_OUT',
  CANCELLED = 'CANCELLED',
  PUBLISHING = 'PUBLISHING',
  PUBLISHED = 'PUBLISHED',
  CLAIMED = 'CLAIMED',
  REJECTED = 'REJECTED',
  ON_LEAVE = 'ON_LEAVE',
  LEAVE_APPROVED = 'LEAVE_APPROVED',

  // Added by Jir - No sure if the following status slots are in the API.
  APPROVED = 'APPROVED',
  NO_SHOW = 'NO_SHOW',
  DECLINED = 'DECLINED',

  //Added by Shaarang - Worker Paid for Shift, User Story- cancel-booking-with-no-fee-pay-team-member
  CANCELLED_PAID = 'CANCELLED_PAID',
  CANCELLED_UNPAID = 'CANCELLED_UNPAID',
  //Worker approved pay for cancelled booking
  SENT_TO_FINANCE = 'SENT_TO_FINANCE',
}

export enum BillingPaymentStatus {
  INITIAL = 'INITIAL',
  REQUIRES_APPROVAL = 'REQUIRES_APPROVAL',
  READY_FOR_BILLING = 'READY_FOR_BILLING',
  SEND_TO_FINANCE = 'SEND_TO_FINANCE',
  PROCESSED = 'PROCESSED',
  REJECTED = 'REJECTED',
  WAIVED = 'WAIVED',
  NO_CHARGE = 'NO_CHARGE',
}

export enum NdisSupportItemDateType {
  ABT = 'ABT',
  TR = 'TR',
  PTNLC = 'PTNLC',
}

export enum ServiceRecurringType {
  ONEOFF = 'ONEOFF',
  RECURRING = 'RECURRING',
}

export enum ServiceLocationType {
  FIXEDVENUE = 'FIXEDVENUE',
  ONLINE = 'ONLINE',
  CUSTOMERLOCATION = 'CUSTOMERLOCATION',
}

export enum ServiceWorkerSelectionType {
  SELECTABLE = 'SELECTABLE',
  ASSIGNED = 'ASSIGNED',
}

export enum ServicePublishStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export enum ServiceType {
  INDIVIDUAL = 'INDIVIDUAL',
  GROUP = 'GROUP',
  COORDINATION = 'COORDINATION',
  INDEPENDENT_LIVING = 'INDEPENDENT_LIVING',
}

export enum ServiceDirection {
  CUSTOMERLOCATION = 'CUSTOMERLOCATION',
  FIXEDVENUE = 'FIXEDVENUE',
}

export enum ServiceTimeType {
  FIXEDTIME = 'FIXEDTIME',
  OPENTIME = 'OPENTIME',
}

export enum NdisSupportTypes {
  CORE = 'CORE',
  CAPITAL = 'CAPITAL',
  CB = 'CAPACITY BUILDING',
}

export enum MessageChannelType {
  IndividualChat = 1,
  GroupChat,
  ProviderToConsumer,
  ProviderToWorker,
  Enquiry,
}

export enum RecurringBookingPattern {
  EveryDay = 1,
  EveryWeek,
  EveryFortnight,
  EveryFourWeeks,
  EveryThreeWeeks,
  Monthly,
}

export enum EditRecurringMode {
  Current = 1, // current attendance
  CurrentAll, // current attendance and all future attendance
  CurrentNext, // current attendance and number of attendance next
  Upcoming, // all attendance that booking status is CONFIRMED, PENDING or ACCEPTED
  AllWorkerUpcoming, // all attendance for all worker that booking status is CONFIRMED, PENDING or ACCEPTED
  AllWorkerCurrentAll, // all attendance for all worker that
  Custom, //for custom recurring booking modal
}

export enum TimezoneSelectorMode {
  MyTimezone = 'MY_TIMEZONE',
  BookingTimezone = 'BOOKING_TIMEZONE',
  CustomTimezone = 'CUSTOM_TIMEZONE',
}

export enum PermissionType {
  NO_ACCESS = 'No access',
  VIEW_ONLY = 'View only',
  MEMBER = 'Member',
  MANAGER = 'Manager',
  CUSTOM = 'Custom',
}

// Local front-end only enums for select states
export enum PaymentPermissionType {
  NO_ACCESS = 'No access',
  MEMBER = 'Member',
  MANAGER = 'Manager',
  FULL_ACCESS = 'Full Access (Owner)',
}

export enum TeamPermissionType {
  VIEW_ONLY = 'View only',
  MANAGE = 'Manage',
  FULL_ACCESS = 'Full Access (Owner)',
  NO_ACCESS = 'No access',
}

export enum CustomerPermissionType {
  VIEW_ONLY = 'View only',
  MANAGER = 'Manager',
  FULL_ACCESS = 'Full Access (Owner)',
}

export enum PlanManagementPermissionType {
  MANAGER = 'Manager',
  NO_ACCESS = 'No access',
  ACCESS = 'Access',
}

export enum FormBuilderPermissionType {
  NO_ACCESS = 'No access',
  ACCESS = 'Access',
}

export enum SubscriptionPermissionType {
  NO_ACCESS = 'No access',
  VIEW_ONLY = 'View only',
  ALL_ACCESS = 'All access',
}

export enum ServicePermissionType {
  NO_ACCESS = 'No access',
  MEMBER = 'Member',
  MANAGER = 'Manager',
  CUSTOM = 'Custom',
  FULL_ACCESS = 'Full Access (Owner)',
}

export enum TeamStatus {
  ENABLED = 'ENABLED',
  DRAFT = 'DRAFT',
  BLOCKED = 'BLOCKED',
  REMOVED = 'REMOVED',
}

export enum InvitationStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
}
export enum InvitationType {
  NEW = 'NEW',
  RE_ENABLED = 'RE-ENABLED',
}

export enum UserPermissionRole {
  PrimaryOwner = 1,
  Owner = 2,
  CustomerAccessManager = 3,
  CustomerAccessViewOnly = 4,
  TeamManager = 5,
  TeamViewOnly = 6,
  PaymentManager = 7,
  PaymentMember = 8,
  ServiceAdmin = 9,
  ServiceMember = 10,
  PlanManager = 11,
  DepartmentManager = 12,
  WorkflowBasic = 13,
  WorkflowElevated = 14,
  WorkflowManager = 15,
  FormBuilder = 16,
  SubscriptionBillingAllAccess = 17,
  SubscriptionBillingViewOnly = 18,
  CustomerViewAll = 19,
  CustomerViewServiceOnly = 20,
  TeamNoAccess = 21,
}

export enum BillingAutoGeneratedType {
  ScheduledTime = 0,
  WorkerCheckinCheckOutTime = 1,
  BeforeBookingTravel = 2,
  DuringBookingTravel = 3,
  PortalCheckinCheckOutTime = 4,
  NonFaceToFaceAdditionalCharge = 5,
  AutoChargedBillingItemsAdditionalCharge = 6,
}

export enum DocumentExpiryStatus {
  EXPIRED = 'EXPIRED',
  EXPIRING_SOON = 'EXPIRING_SOON',
  NOT_EXPIRED = 'NOT_EXPIRED',
}

export enum PaymentSourceType {
  INVOICE = 'INVOICE',
  NDIS = 'NDIS',
  VCP = 'VCP',
}

export enum DateFilterPeriodModes {
  ALL = 'ALL',
  TODAY = 'TODAY',
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  CUSTOM = 'CUSTOM',
}

export enum FilterType {
  SEARCH = 'search',
  CUSTOMER = 'customerUserIds',
  WORKER = 'supportWorkerIds',
  SERVICE = 'serviceIds',
  BOOKING_STATUS = 'status',
  RECURRING = 'isRecurring',
  PAYMENT_STATUS = 'paymentStatus',
  DATE_RANGE = 'startDate',
  NOTE_TYPE = 'noteType',
  IS_INCIDENT = 'isIncident',
  NOTE_AUTHOR = 'noteAuthorIds',
  NOTE_SERVICE = 'noteService',
  SERVICE_STATUS = 'serviceStatus',
  SERVICE_DEPARTMENT = 'serviceDepartmentIds',
  SERVICE_TYPE = 'serviceType',
  NDIS_CATEGORIES = 'ndisCategories',
  PROVIDER = 'planManagementSupplierIds',
  SUPPLIER_PAYMENT_STATUS = 'supplierPaymentStatus',
  APPROVAL_STATUS = 'approvalStatus',
  SERVICE_AGREEMENT_STATUS = 'serviceAgreementStatus',
  CLAIM_STATUS = 'claimStatus',
  BOOKING_ERROR_TYPES = 'bookingErrorTypes',
  QUALIFICATIONS = 'qualifications',
  SPECIALITIES = 'specialities',
  RELIGIONS = 'religions',
  GENDER = 'gender',
  SKILLS = 'skills',
  LANGUAGES = 'languages',
  INTEREST = 'interests',
  CUSTOM_CUSTOMER = 'customCustomerUserIds',
  PAYMENT_METHODS = 'paymentMethods',
  FUNDING_SOURCES = 'fundingMethods',
  PAYMENT_WARNING = 'paymentWarnings',
  BATCH_AUTHOR = 'batchAuthorIds',
  SHOW_ARCHIVED_BOOKINGS = 'showArchivedBookings',
  PINNED_ALERTS = 'hasCustomerPinnedAlert',
  SESSION_STATUS = 'sessionStatus',
  SESSION_STAFFING_STATUS = 'sessionStaffingStatus',
  SHIFT_SLOT_WARNINGS = 'showWarningsOnly',
  SHIFT_SLOT_STATUS = 'shiftSlotStatus',
  CUSTOMER_CONNECTION_STATUS = 'connectionStatus',
  MANAGEMENT_METHOD = 'managementMethod',
  USER_LOCATION_BY_STATE = 'userLocationByState',
  CUSTOMER_STATUS = 'customerStatus',
  PERMANENT_CONDITIONS = 'permanentConditions',
  CUSTOMER_MANAGED_BY = 'isManaged',
  PREFERRED_SUPPORT_WORKER = 'hasPreferredSupportWorkers',
  BLOCKED_SUPPORT_WORKER = 'hasBlockedSupportWorkers',
  BOOKING_TYPES = 'bookingTypes',
  WORKER_ALERTS = 'supportWorkerAlertTypes',
  CUSTOMER_ALERTS = 'alertTypes',
  SERVICE_DATE_TIMES = 'serviceDateTimeIds',
  GROUP_SERVICE_SCHEDULES = 'serviceScheduleIds',
  LOCATION_BY_SUBURBS = 'locationPostcodes',
  INVOICE_DATE = 'invoiceDate',
  DATE_PAID = 'datePaid',
  SUPPORT_COORDINATOR = 'supportCoordinators',
  CASE_MANAGER = 'caseManagers',
  INVOICE_STATUS = 'invoiceStatus',
  WORKFLOW_TEMPLATE_TYPE = 'workflowTemplateType',
  WORKFLOW_TEMPLATE_STATUS = 'workflowTemplateStatus',
  ACTIVE_WORKFLOWS_DATE = 'activeWorkflowsDate',
  ACTIVE_WORKFLOWS_STATUS = 'activeWorkflowsStatus',
  REQUIRE_APPROVAL_WORKFLOW_STATUS = 'requireApprovalWorkflowStatus',
  ALL_WORKFLOWS_STATUS = 'allWorkflowStatus',
  FORM_STATUS = 'formStatus',
  WORKFLOW_STATUS = 'workflowStatus',
  SLEEPOVER = 'hasSleepover',
  REJECTION_STATUS = 'rejectionStatus',
  AVAILABILITY_CHANGE_REQUESTS = 'availabilityChangeRequested',
  BOOKING_BILLING = 'isNonBillable',
  PAID_BILLING = 'isNonBillablePaid',
  BATCH_BILLING = 'isNonBillableBatch',
  DATE_ADDED = 'createdOn',
  FORM_TYPE = 'formType',
  DEBTOR = 'debtorIds',
}

export enum FilterLabel {
  customerUserIds = 'Customer',
  customCustomerUserIds = 'Customer',
  supportWorkerIds = 'Team member',
  serviceIds = 'Service',
  status = 'Booking status',
  isRecurring = 'Is recurring booking',
  paymentStatus = 'Payment status',
  startDate = 'Date range',
  noteType = 'Note type',
  isIncident = 'Incident?',
  noteService = 'Service',
  noteAuthorIds = 'Author',
  ndisCategories = 'Support categories',
  serviceStatus = 'Service status',
  serviceDepartmentIds = 'Service Department',
  serviceType = 'Service Type',
  planManagementSupplierIds = 'Provider',
  supplierPaymentStatus = 'Provider payment status',
  approvalStatus = 'Approval status',
  claimStatus = 'Claim status',
  bookingErrorTypes = 'Warnings/Errors',
  qualifications = 'Qualifications',
  specialities = 'Specialities',
  religions = 'Religions',
  gender = 'Gender',
  skills = 'Skills',
  languages = 'Languages Spoken',
  interests = 'Interests',
  paymentMethods = 'Management methods',
  fundingMethods = 'Funding methods',
  paymentWarnings = 'Warnings',
  batchAuthorIds = 'Batch authors',
  showArchivedBookings = 'Show archived bookings',
  hasCustomerPinnedAlert = 'Has pinned alerts',
  sessionStatus = 'Status',
  showWarningsOnly = 'Warnings',
  shiftSlotStatus = 'Team member status',
  connectionStatus = 'Connection status',
  managementMethod = 'Management method',
  userLocationByState = 'Location',
  customerStatus = 'Customer status',
  permanentConditions = 'Permanent conditions',
  isManaged = 'Managed by',
  hasPreferredSupportWorkers = 'Preferred team members',
  hasBlockedSupportWorkers = 'Blocked team members',
  bookingTypes = 'Booking types',
  supportWorkerAlertTypes = 'Alerts',
  alertTypes = 'Alerts',
  serviceDateTimeIds = 'Group service sessions',
  serviceScheduleIds = 'Group service schedules',
  locationPostcodes = 'Location (Postcode)',
  invoiceDate = 'Invoice date',
  datePaid = 'Date paid',
  invoiceStatus = 'Invoice Status',
  supportCoordinators = 'Support coordinators',
  caseManagers = 'Case managers',
  activeWorkflowsDate = 'Date',
  activeWorkflowsStatus = 'Status',
  requireApprovalWorkflowStatus = 'Status',
  allWorkflowStatus = 'Status',
  hasSleepover = 'Is sleepover booking',
  rejectionStatus = 'Rejection status',
  availabilityChangeRequested = 'Availability change requests',
  sessionStaffingStatus = 'Staffing status',
  isNonBillable = 'Billing',
  isNonBillablePaid = 'Billing',
  isNonBillableBatch = 'Billing',
  createdOn = 'Date added',
  debtorIds = 'Debtor',
}

export enum ServiceAgreementStatus {
  DRAFT = 'DRAFT',
  UNDER_CUSTOMER_REVIEW = 'UNDER_CUSTOMER_REVIEW',
  SIGNED = 'SIGNED',
  ACTIVE = 'ACTIVE',
  UPCOMING = 'UPCOMING',
  EXPIRED = 'EXPIRED',
  UNSIGNED = 'UNSIGNED',
  EXPIRING_SOON = 'EXPIRING_SOON',
}

export enum FundingPackageStatus {
  ACTIVE = 'ACTIVE',
  UPCOMING = 'UPCOMING',
  EXPIRED = 'EXPIRED',
}

export enum NoteVisibleType {
  PORTAL = 'PORTAL',
  PORTAL_AND_APP = 'PORTAL_AND_APP',
  RESTRICTED_TO_SERVICE = 'RESTRICTED_TO_SERVICE',
  RESTRICTED_TO_SERVICE_PORTAL_AND_APP = 'RESTRICTED_TO_SERVICE_PORTAL_AND_APP',
  PRIVATE_PORTAL_ONLY = 'PRIVATE_PORTAL_ONLY',
}

export enum NDISLineItemUnit {
  H = 'Hourly',
  EA = 'Each',
  D = 'Daily',
  YR = 'Yearly',
  WK = 'Weekly',
}

export enum ServiceStatus {
  UNPUBLISHED = 'UNPUBLISHED',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
}

export enum ServiceStatusLabel {
  UNPUBLISHED = 'Unpublished',
  DRAFT = 'Draft',
  PUBLISHED = 'Published',
  ARCHIVED = 'ARCHIVED',
}

export enum MmmGroup {
  NonRemote,
  Remote,
  VeryRemote,
}

export enum PlanManagerType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export enum PlanManagementApprovalStatus {
  NOT_SENT = 'NOT_SENT',
  SENT = 'SENT',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum PlanManagementApprovalStatusDisplay {
  NOT_SENT = 'Not sent for Approval',
  SENT = 'Sent for Approval',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export enum PlanManagementPaymentStatus {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
  FAILED = 'FAILED',
}

export enum PlanManagementPaymentStatusDisplay {
  UNPAID = 'Unpaid',
  PAID = 'Paid',
  FAILED = 'Payment Issue',
}

export enum PlanManagementClaimStatus {
  UNCLAIMED = 'UNCLAIMED',
  CANCELLED = 'CANCELLED',
  WAIVED = 'WAIVED',
  PARTIALLY_REJECTED = 'PARTIALLY_REJECTED',
  PARTIALLY_SENT_TO_PAYMENTS = 'PARTIALLY_SENT_TO_PAYMENTS',
  PARTIALLY_CLAIMED = 'PARTIALLY_CLAIMED',
  REJECTED = 'REJECTED',
  CLAIMED = 'CLAIMED',
  SENT_TO_PAYMENTS = 'SENT_TO_PAYMENTS',
  READY_TO_BE_PAID = 'READY_TO_BE_PAID',
  PARTIALLY_UNCLAIMED = 'PARTIALLY_UNCLAIMED',
}

export enum PlanManagementClaimStatusDisplay {
  UNCLAIMED = 'Unclaimed',
  CANCELLED = 'Cancelled',
  WAIVED = 'Waived',
  PARTIALLY_REJECTED = 'Partially rejected',
  PARTIALLY_SENT_TO_PAYMENTS = 'Partially sent to payment',
  PARTIALLY_CLAIMED = 'Partially claimed',
  REJECTED = 'Rejected',
  CLAIMED = 'Claimed',
  SENT_TO_PAYMENTS = 'Sent to payment',
  READY_TO_BE_PAID = 'Ready to be paid',
  PARTIALLY_UNCLAIMED = 'Partially unclaimed',
}

export enum NdisClaimType {
  STANDARD = 'STANDARD',
  CANCELLATION = 'CANCELLATION',
  PROVIDER_TRAVEL = 'PROVIDER_TRAVEL',
  REPORT_WRITING = 'REPORT_WRITING',
  NON_FACE_TO_FACE = 'NON_FACE_TO_FACE',
  TELEHEALTH_SUPPORTS = 'TELEHEALTH_SUPPORTS',
  IRREGULAR_SIL_SUPPORTS = 'IRREGULAR_SIL_SUPPORTS',
}

export enum NdisClaimTypeLabel {
  STANDARD = 'Direct Support',
  CANCELLATION = 'Cancellation',
  PROVIDER_TRAVEL = 'Provider Travel',
  REPORT_WRITING = 'Report writing',
  NON_FACE_TO_FACE = 'Non face-to-face',
  TELEHEALTH_SUPPORTS = 'Telehealth supports',
  IRREGULAR_SIL_SUPPORTS = 'Irregular SIL supports',
}

export enum ClaimType {
  DIRECT_SERVICE = 'STD',
  STANDARD_SERVICE = 'STD',
  CANCELLATION = 'CANC',
  TRAVEL = 'TRAN',
  REPORT_WRITING = 'REPW',
  NON_FACE_TO_FACE = 'NF2F',
  TELEHEALTH_SUPPORTS = 'THLT',
  IRREGULAR_SIL_SUPPORTS = 'IRSS',
}

export enum NdisCancellationReason {
  NSDH = 'NSDH',
  NSDF = 'NSDF',
  NSDT = 'NSDT',
  NSDO = 'NDSO',
}

export enum NdisCancellationReasonLabel {
  NSDH = 'No show due to health reasons',
  NSDF = 'No show due to family issues',
  NSDT = 'No show due to unavailability of transport',
  NSDO = 'No show due to other reasons',
}

export enum NdisUnitLabel {
  H = 'Hourly',
  EA = 'Each',
}

export enum SessionStatus {
  SCHEDULED = 'SCHEDULED',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
}

export enum SessionStatusLabel {
  SCHEDULED = 'Scheduled',
  INPROGRESS = 'In progress',
  COMPLETED = 'Completed',
  CLOSED = 'Closed',
  CANCELLED = 'Cancelled',
}

export enum SessionStaffingStatus {
  OPTIMALLY_STAFFED = 'OPTIMALLY_STAFFED',
  UNDERSTAFFED = 'UNDERSTAFFED',
  OVERSTAFFED = 'OVERSTAFFED',
}

export enum SessionStaffingStatusLabel {
  OPTIMALLY_STAFFED = 'Optimally staffed',
  UNDERSTAFFED = 'Understaffed',
  OVERSTAFFED = 'Overstaffed',
}

export enum BookingErrorType {
  PaymentMethodError = 1,
  BookingRecordedTimeWarning = 2,
  ServiceAgreementWarning = 3,
  FundingPackageWarning = 4,
  BookingActualTimeWarning = 5,
  BookingCheckinTimeWarning = 6,
  BookingCheckOutTimeWarning = 7,
  ServiceAgreementLineItemWarning = 8,
  SleepoverConflictBookingTime = 9,
  SleepoverConflictCheckinoutTime = 10,
  DisturbancOutOfSleepoverTimeError = 11,
  DisturbancOutOfBookingTimeError = 12,
  DisturbancOutOfCheckinCheckoutTimeError = 13,
}

export enum PaymentSources {
  NDIS = 'NDIS',
  VCP = 'VCP',
}

export enum VCPSupportItemDateTypeLabel {
  PBH = 'Public Holiday rate',
  SAT = 'Saturday rate',
  SUN = 'Sunday rate',
  WKD = 'Mon - Fri rate',
  WKE = 'After Hours rate',
  NSO = 'All',
  OVN = 'Night rate',
}

export enum CustomerSupportWorkerPreference {
  BLOCKED = 'BLOCKED',
  PREFERRED = 'PREFERRED',
}

export enum BookingErrorTimeSource {
  ACTUAL_TIME = 'ACTUAL_TIME',
  RECORD_TIME = 'RECORD_TIME',
}

export enum CustomerActiveType {
  ACTIVE = 'ACTIVE',
  DORMANT = 'DORMANT',
}

export enum ScheduleType {
  ONE_OFF = 'ONE_OFF',
  SCHEDULE = 'SCHEDULE',
}

export enum GroupServiceSessionStatus {
  SCHEDULED = 'SCHEDULED',
  INPROGRESS = 'INPROGRESS',
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  COMPLETED = 'COMPLETED',
}

export enum TeamMemberCustomerRatio {
  ONE_TO_ONE = '1:1',
  ONE_TO_TWO = '1:2',
  ONE_TO_THREE = '1:3',
  ONE_TO_FOUR = '1:4',
  ONE_TO_FIVE = '1:5',
  ONE_TO_SIX = '1:6',
  TWO_TO_ONE = '2:1',
}

export enum GroupServiceClassification {
  COMMUNITY = 'COMMUNITY',
  CENTRE_BASED = 'CENTRE_BASED',
}

export enum PriceFrenquency {
  PERHOUR = 'PERHOUR',
  PERDAY = 'PERDAY',
  PERWEEK = 'PERWEEK',
  PERMONTH = 'PERMONTH',
  PERYEAR = 'PERYEAR',
  PEREACH = 'PEREACH',
}

export enum TransportAttendanceType {
  PRE_SESSION = 'PRE_SESSION',
  POST_SESSION = 'POST_SESSION',
}

export enum GroupBookingActionType {
  INDIVIDUAL_SESSIONS = 'INDIVIDUAL_SESSIONS',
  SCHEDULES = 'SCHEDULES',
}

export enum CustomerType {
  ALL = 'ALL',
  CUSTOMERS = 'CUSTOMERS',
  PARENT_GUARDIANS = 'PARENT_GUARDIANS',
  ENQUIRIES = 'ENQUIRIES',
}

export enum ShiftWarningType {
  LATE_START = 1,
  LATE_FINISH = 2,
  EARLY_START = 3,
  EARLY_FINISH = 4,
  NO_SHOW = 5,
  SHIFT_UNFINISHED = 6,
  NO_WARNINGS = 7,
}

export enum WorkerAssignmentWarningType {
  UNAVAILABLE = 'unavailable',
  IN_SESSION = 'inSession',
  CAUTION = 'caution',
  SHIFT_CLASH = 'shiftClash',
}

export enum OutsideAvailabilityType {
  UNAVAILABILITY = 'UNAVAILABILITY',
  OUTSIDE_GENERAL_AVAILABILITY = 'OUTSIDE_GENERAL_AVAILABILITY',
}

export enum SupportWorkerAvailability {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  LIMITED_AVAILABILITY = 'LIMITED_AVAILABILITY',
}

export enum MessageSubscriberType {
  PortalUser = 1,
  Customer,
  SupportWorker,
  None,
  ServiceProvider,
}

export enum CustomerConnectionStatus {
  CONNECTED = 'CONNECTED',
  NOT_CONNECTED = 'NOT_CONNECTED',
  PENDING = 'PENDING',
}

export enum CustomerStatusType {
  ACTIVE = 'ACTIVE',
  ENQUIRY = 'ENQUIRY',
  ARCHIVED = 'ARCHIVED',
  SCHEDULED_TO_BE_ARCHIVED = 'SCHEDULED_TO_BE_ARCHIVED',
}

export enum BookingType {
  BOOKING = 'BOOKING',
  ACTIVITY_RECORD = 'ACTIVITY_RECORD',
}

export enum AlertLevel {
  ALL = 'ALL',
  HIGH = 1,
  MEDIUM = 2,
  LOW = 3,
}

export enum CustomerAlertType {
  DocumentExpired = 0,
  DocumentExpiringSoon = 1,
  OverBudgetActual = 2,
  OverBudgetForecasted = 3,
  ServiceAgreementExpired = 4,
  ServiceAgreementExpiringSoon = 5,
}

export enum CustomViewsModalType {
  MANAGE = 'MANAGE',
  DUPLICATE = 'DUPLICATE',
  DELETE = 'DELETE',
  CREATE = 'CREATE',
  SELECT_TEAM = 'SELECT_TEAM',
  SAVE_VIEW = 'SAVE_VIEW',
  SAVE_DEFAULT_VIEW = 'SAVE_DEFAULT_VIEW',
  SAVE_AS_NEW = 'SAVE_AS_NEW',
  SAVE_AS_NEW_COPY_FROM_OTHERS = 'SAVE_AS_NEW_COPY_FROM_OTHERS',
}

export enum CustomViewsViewCardType {
  DEFAULT = 'DEFAULT',
  CREATED_BY_ME = 'CREATED_BY_ME',
  CREATED_BY_OTHERS = 'CREATED_BY_OTHERS',
}

export enum CustomViewsType {
  PRIVATE = 1,
  TEAM = 2,
  EVERYONE = 3,
}

export enum SupportWorkerAlertType {
  ComplianceDocumentExpired = 1,
  DocumentExpired = 2,
  ComplianceDocumentExpiringSoon = 3,
  DocumentExpiringSoon = 4,
  ScheduledToBeRemoved = 5,
}

export enum CareInformationType {
  PERMANENT = 1,
  MEDICATION = 2,
  SUPPORTNEEDS = 3,
  BEHAVIOUR = 4,
  GENERAL = 5,
}

export enum DebtorType {
  SELF = 1,
  EXTERNAL_PLAN = 2,
  INTERNAL_PLAN = 3,
  VCP = 4,
}

export enum InvoiceStatus {
  PROCESSED = 'PROCESSED',
  PAID = 'PAID',
}

export enum FormStatus {
  ACTIVE = 'ACTIVE',
  INUSE = 'INUSE',
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
}

export enum FormType {
  CUSTOM = 'CUSTOM',
  INTAKE = 'INTAKE',
  INCIDENT = 'INCIDENT',
}

export enum FormActions {
  ARCHIVE = 'Archive',
  COPY = 'Copy',
  EDIT = 'Edit',
  RE_ACTIVATE = 'Re-activate',
}

export enum WorkflowStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ArchiveDebtorFrom {
  LISTING = 'LISTING',
  DETAILS_PAGE = 'DETAILS_PAGE',
}

export enum WorkflowTemplateType {
  MANUAL = 'MANUAL',
  PLATFORM = 'PLATFORM',
}

export enum WorkflowTemplateTriggerType {
  MANUAL = 'MANUAL',
  INCIDENT = 'INCIDENT_NOTE',
}

export enum WorkflowTemplateStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum ActiveWorkflowsStatus {
  COMPLETED = 'COMPLETED',
  INPROGRESS = 'INPROGRESS',
  CLOSED = 'CLOSED',
}

export enum ActiveWorkflowType {
  COMPLETED = 'COMPLETED',
  INPROGRESS = 'INPROGRESS',
  FLAGGED = 'FLAGGED',
}

export enum WorkflowTemplateStepType {
  TRIGGER = 'TRIGGER',
  STEP = 'STEP',
}

export enum WorkflowTemplateStepApprovalType {
  ANYONE = 'ANYONE',
  ROLE = 'ROLE',
  TEAM_MEMBER = 'SPECIFIC',
}

export enum WorkflowsStatus {
  COMPLETED = 'COMPLETED',
  INPROGRESS = 'INPROGRESS',
  CLOSED = 'CLOSED',
  FLAGGED = 'FLAGGED',
}

export enum WorkflowTriggerModuleType {
  GROUP_BOOKING = 'GROUP_BOOKING',
  SESSION_NOTE = 'SESSION_NOTE',
}

export enum WorkflowPermissionType {
  BASIC = 'Basic',
  ELEVATED = 'Elevated',
  MANAGER = 'Manager',
}

export enum ActivityGroupsAddingType {
  NO = 'No activity groups',
  APPLY_FROM_SERVICE = 'Use existing activity groups from this service',
  CREATE_NEW = 'Create new activity groups for this session',
}
export enum AddingActivityGroupItemType {
  CREATE_NEW = 1,
  FROM_TEMPLATE = 2,
}
export enum ActivityGroupAddEditType {
  CUSTOMER = 'CUSTOMER',
  TEAM_MEMBER = 'TEAM_MEMBER',
}

export enum ActivityGroupMemberType {
  CUSTOMER = 'CUSTOMER',
  TEAM_MEMBER = 'TEAM_MEMBER',
}

export enum ActivityGroupModalType {
  CREATE = 'CREATE',
  DUPLICATE = 'DUPLICATE',
  DELETE = 'DELETE',
  REMOVE = 'REMOVE',
  REMOVE_ALL = 'REMOVE_ALL',
  ADD_CUSTOMER = 'ADD_CUSTOMER',
  ADD_TEAM_MEMBER = 'ADD_TEAM_MEMBER',
  ADD_BOTH = 'ADD_BOTH',
  EDIT = 'EDIT',
  ASSIGN_CUSTOMER_TO_ACTIVITY_GROUP = 'ASSIGN_CUSTOMER_TO_ACTIVITY_GROUP',
  ASSIGN_TEAM_MEMBER_TO_ACTIVITY_GROUP = 'ASSIGN_TEAM_MEMBER_TO_ACTIVITY_GROUP',
  EDIT_SESSION = 'EDIT_SESSION',
  ASSIGN_ACTIVITY_GROUP_TO_SESSION = 'ASSIGN_CUSTOMER_TO_SESSION',
  ASSIGN_ACTIVITY_GROUP_TO_SCHEDULE = 'ASSIGN_CUSTOMER_TO_SCHEDULE',
}

export enum UnavailabilityType {
  ONE_OFF = 1,
  CUSTOM = 2,
}

export enum CustomUnavailabilityOption {
  UnavailableAllDay = 0,
  AvailableAllDay = 1,
  UnavailableSpecificHours = 2,
}

export enum AvailabilityUnavailabilityRequestStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  REQUEST_CANCELLATION = 'REQUEST_CANCELLATION',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export enum AvailabilityUnavailabilityRequestModalType {
  AVAILABILITY = 1,
  UNAVAILABILITY = 2,
}

export enum AvailabilityUnavailabilityRequestTabType {
  AVAILABILITY_REQUESTS = 1,
  UNAVAILABILITY_REQUESTS = 2,
  APPROVED = 3,
  DECLINED = 4,
  CANCELLED = 5,
}

export enum TimeBasedAlertConfigType {
  DOCUMENT_EXPIRY = 'DOCUMENT_EXPIRY',
  SERVICE_AGREEMENT_EXPIRY = 'SERVICE_AGREEMENT_EXPIRY',
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  TRIALING = 'trialing',
  PAUSED = 'paused',
  NON_PAYG = 'none-payg',
}

export enum InvoiceHistoryStatus {
  PAID = 'PAID',
  PAYMENT_ISSUE = 'PAYMENT_ISSUE',
  WAIVED = 'WAIVED',
  REFUNDED = 'REFUNDED',
}

export enum BookingActionType {
  CONFIRM = 'confirm',
  ASSIGN = 'assign',
}

export enum NotificationTypeSuccess {
  ONE_BOOKING_NOT_REMOVE_MEMBER = 1,
  ONE_BOOKING_REMOVE_MEMBER,
  RECURRING_BOOKING_ONLY_ONE_NOT_REMOVE_MEMBER,
  RECURRING_BOOKING_ONLY_ONE_REMOVE_MEMBER,
  RECURRING_BOOKING_MULTIPLE,
}

export enum IntegratedStatus {
  CONNECTED = 'CONNECTED',
  DISCONNECT = 'DISCONNECT',
  FAILED = 'FAILED',
}

export enum SleepoverType {
  NONE = 'NONE',
  ENTIRE = 'ENTIRE',
  PARTIAL = 'PARTIAL',
}

export enum SleepoverTimeSlotErrorType {
  OVERLAP = 'OVERLAP',
  OUT_OF_BOOKING_TIME = 'OUT_OF_BOOKING_TIME',
  OUT_OF_SLEEPOVER_TIME = 'OUT_OF_SLEEPOVER_TIME',
  OUT_OF_CHECKIN_CHECKOUT_TIME = 'OUT_OF_CHECKIN_CHECKOUT_TIME',
}

export enum DisturbanceErrorType {
  OUT_OF_BOOKING_TIME = 'OUT_OF_BOOKING_TIME',
  OUT_OF_CHECKIN_CHECKOUT_TIME = 'OUT_OF_CHECKIN_CHECKOUT_TIME',
  OUT_OF_SLEEPOVER_TIME = 'OUT_OF_SLEEPOVER_TIME',
  OVERLAP = 'OVERLAP',
}

export enum ApprovalBookingErrorType {
  SLEEPOVER = 'sleepover',
  DISTURBANCE = 'disturbance',
  BILLING = 'billing',
  MULTIPLE = 'multiple',
}

export enum TimeType {
  NORMAL = 'normal',
  SLEEPOVER = 'sleepover',
  DISTURBANCE = 'disturbance',
}

export enum NotePrivacy {
  PORTAL = 'Public (workspace only)',
  PORTAL_AND_APP = 'Public (workspace & app)',
  RESTRICTED_TO_SERVICE = 'Restricted to service (workspace only)',
  RESTRICTED_TO_SERVICE_PORTAL_AND_APP = 'Restricted to service (workspace & app)',
  PRIVATE_PORTAL_ONLY = 'Private (workspace only)',
}

export enum DurationDisplayFormat {
  HOURS_AND_MINUTES = 'HOURS_AND_MINUTES',
  MINUTES = 'MINUTES',
}

export enum DurationUnit {
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
}

export enum CustomerDetailsTabKey {
  GENERAL_INFO = 'generalinfo',
  MANAGED_ACCOUNTS = 'managed-accounts',
  LINKED_ACCOUNTS_APP = 'linkedaccounts-app',
  CONTACTS = 'contacts',
  FUNDING = 'fundings',
  SERVICE_AGREEMENTS = 'new_service_agreements',
  BUDGETING = 'budgeting',
  FILES = 'files',
  NOTES = 'notes',
  FORMS = 'forms',
  WORKFLOWS = 'workflows',
  CARE_INFO = 'careInformation',
  CARER_PREFERENCES = 'carer_preferences',
  BOOKINGS = 'bookings',
}

export enum DocumentFileType {
  CUSTOMER_DOCUMENT = 'CUSTOMER_DOCUMENT',
  SUPPORT_WORKER_DOCUMENT = 'SUPPORT_WORKER_DOCUMENT',
  PLAN_MANAGEMENT_INVOICE_DOCUMENT = 'PLAN_MANAGEMENT_INVOICE_DOCUMENT',
  NOTE_DOCUMENT = 'NOTE_DOCUMENT',
  BOOKING_DOCUMENT = 'BOOKING_DOCUMENT',
}

export enum ProcessedPaymentAction {
  PROCESSED = 'PROCESSED',
  REAPPROVED = 'REAPPROVED',
  REJECTED = 'REJECTED',
  WAIVED = 'WAIVED',
}

export enum AvailabilityRequestType {
  YES = 'YES',
}

export enum TooltipType {
  UNIT_PRICE = 'unitPrice',
  QTY = 'qty',
  TRAVEL_DISTANCE = 'travelDistance',
  ADDITIONAL_COST = 'additionalCost',
}

export enum GeneralAvailabilityWeekCycle {
  WEEKLY = 'weekly',
  FORTNIGHTLY = 'fortnightly',
}

export enum StaffingSessionStatus {
  OPTIMALLY_STAFFED = 'OPTIMALLY_STAFFED',
  SLIGHTLY_UNDERSTAFFED = 'SLIGHTLY_UNDERSTAFFED',
  VERY_UNDERSTAFFED = 'VERY_UNDERSTAFFED',
  SLIGHTLY_OVERSTAFFED = 'SLIGHTLY_OVERSTAFFED',
  VERY_OVERSTAFFED = 'VERY_OVERSTAFFED',
  MOSTLY_OVERSTAFFED = 'MOSTLY_OVERSTAFFED',
  MOSTLY_UNDERSTAFFED = 'MOSTLY_UNDERSTAFFED',
  UNDERSTAFFED_AND_OVERSTAFFED = 'UNDERSTAFFED_AND_OVERSTAFFED',
}

export enum StaffingStatus {
  UNDERSTAFFED = 'UNDERSTAFFED',
  CORRECTLY_STAFFED = 'CORRECTLY_STAFFED',
  OVERSTAFFED = 'OVERSTAFFED',
}

export enum BillableType {
  ALL_BILLABLE = 'ALL_BILLABLE',
  SOME_BILLABLE = 'SOME_BILLABLE',
  NON_BILLABLE = 'NON_BILLABLE',
  NO_LINE_ITEM = 'NO_LINE_ITEM',
}

export enum BillableDisplayType {
  NON_BILLABLE = 'Non-billable',
  BILLABLE = 'Billable',
}

export enum NonFaceToFaceFrequency {
  PER_HOUR = 'hour',
  PER_BOOKING = 'booking',
}

export enum BrowserType {
  FIREFOX = 'Firefox',
  SAFARI = 'Safari',
  IE = 'ie',
  EDGE = 'Edge',
  CHROME = 'Chrome',
  EDGE_CHROMIUM = 'EdgeChromium',
  OPERA = 'OPERA',
  UNKNOWN = 'Unknown',
}

export enum ServiceDepartmentPermissionType {
  MANAGER_OF_ALL_SERVICES = 'Manager of all services',
  NO_ACCESS = 'No access',
}

export enum DaysOfWeek {
  MON = 'Mon',
  TUE = 'Tue',
  WED = 'Wed',
  THU = 'Thu',
  FRI = 'Fri',
  SAT = 'Sat',
  SUN = 'Sun',
}

export enum DisplayDaysOfWeek {
  MON = 'Mondays',
  TUE = 'Tuesdays',
  WED = 'Wednesdays',
  THU = 'Thursdays',
  FRI = 'Fridays',
  SAT = 'Saturdays',
  SUN = 'Sundays',
}

export enum DocumentStatus {
  SCANNED = 'SCANNED',
  SCANNING = 'SCANNING',
}

export enum CustomerViews {
  ASSIGNED_CUSTOMERS = 'ASSIGNED_CUSTOMERS',
  SUPPORT_COORDINATION_CUSTOMERS = 'SUPPORT_COORDINATION_CUSTOMERS',
}

export enum DisplayFormType {
  INCIDENT = 'Incident',
  INTAKE = 'Intake',
  CUSTOM = 'Custom',
}

export enum WorkTypeCategory {
  Leave = 'LEAVE_TYPE',
  WorkType = 'WORK_TYPE',
}

export enum WorkTypeState {
  ACTIVE = 'Active',
  INACTIVE = 'Archived',
}

export enum ExportModals {
  CUSTOMER = 'CUSTOMER',
  BOOKING = 'BOOKING',
  TEAM_MEMBER_GENERAL = 'TEAM_MEMBER_GENERAL',
  TEAM_MEMBER_TAGS = 'TEAM_MEMBER_TAGS',
  TEAM_MEMBER_AVAILABILITIES = 'TEAM_MEMBER_AVAILABILITIES',
  TEAM_MEMBER_DOCUMENTS = 'TEAM_MEMBER_DOCUMENTS',
  STAFF_ADDTIONAL_CLAIMS = 'STAFF_ADDTIONAL_CLAIMS',
  INCIDENT = 'INCIDENT',
  PINNED_ALERTS = 'PINNED_ALERTS'
}
