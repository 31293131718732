import React, { Component } from 'react';
import { Col, Form, Row, Tabs, Checkbox, notification } from 'antd';
import { Text, Title } from 'common-components/typography';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import Utils from 'utilities/Utils';
import NDISLineItemGrid from 'common-components/line-items/NDISLineItemGrid';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import NumberInput from 'common-components/inputs/NumberInput';
import AddNDISLineItemsModal from 'views/services/listing/components/AddNDISLineItemsModal';
import _ from 'lodash';
import CommonUtils from 'utilities/common-utils';
import { ServiceType } from 'utilities/enum-utils';
import { ServDetailsSectionHeader } from 'views/services/details/sections/content-section/common/ServDetailsSectionHeader';
import FullScreenScrollableModal from 'common-components/modal/FullScreenScrollableModal';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';

const { TabPane } = Tabs;

const RECOMMENDED_TRAVEL_PRICE = 1.00;

interface IServiceNDISLineItemsModalProps extends FormComponentProps {
  isOpen: boolean;
  onClose: any;
  selectedService: typeof state.servicesStore.selectedService;
  doUpdateServicePayment: typeof dispatch.servicesStore.doUpdateServicePayment;
  hasEditPermission: boolean;
  //   history: any;
}

interface IServiceNDISLineItemsModalState {
  selectedTab: string;
  isAddModalOpen: boolean;
  serviceBillingItems: any;
  isWarningTravelPriceBefore: boolean;
  isWarningTravelPriceDuring: boolean;
  isEdit: boolean;
  isDiscardChangesModalOpen: boolean;
}

class ServiceNDISLineItemsModal extends Component<IServiceNDISLineItemsModalProps, IServiceNDISLineItemsModalState> {
  state = {
    selectedTab: 'lineItems',
    isAddModalOpen: false,
    serviceBillingItems: this.props.selectedService.ndisServiceBillingItems
      ? _.cloneDeep(this.props.selectedService.ndisServiceBillingItems)
      : [],
    isWarningTravelPriceBefore: false,
    isWarningTravelPriceDuring: false,
    isEdit: false,
    isDiscardChangesModalOpen: false,
  };

  private _setSelectedTab = (tab) => {
    this.setState({ selectedTab: tab });
  };

  private _closeLineItemModal = () => {
    this.setState({ isAddModalOpen: false });
  };

  private _openLineItemModal = () => {
    this.setState({ isAddModalOpen: true });
  };

  private _saveLineItem = (billingItems) => {
    let newBillingItems = _.clone(this.state.serviceBillingItems);
    billingItems.map((lineItem) => {
      let priority = null;
      if (
        !Utils.isEmpty(lineItem.GroupCode) &&
        _.find(newBillingItems, (existingLines) => existingLines.groupCode === lineItem.GroupCode)
      ) {
        priority = _.find(newBillingItems, (existingLines) => existingLines.groupCode === lineItem.GroupCode).priority;
      } else {
        let prioritizedLineItems = _.clone(newBillingItems);
        _.sortBy(prioritizedLineItems, 'priority', 'desc');
        let lastLineItem = prioritizedLineItems ? prioritizedLineItems.pop() : null;
        priority = lastLineItem && lastLineItem.priority ? lastLineItem.priority + 1 : 1;
      }
      newBillingItems.push({
        supportItemNumber: lineItem.SupportItemNumber,
        supportCategoryNumber: Number(lineItem.SupportCategoryNumber),
        price: {
          nonRemotePrice: lineItem.NationalNonRemote ? Number(lineItem.NationalNonRemote) : 0,
          remotePrice: Number(lineItem.NationalRemote),
          veryRemotePrice: Number(lineItem.NationalVeryRemote),
          actPrice: Number(lineItem.ACTPrice),
          ntPrice: Number(lineItem.NTPrice),
        },
        tax: 0,
        unit: lineItem.UnitOfMeasure,
        dateType: lineItem.DateType,
        billingProvider: 'NDIS',
        label: lineItem.SupportItem,
        priceControl: lineItem.PriceControl,
        priority,
        mileagePrice: 0,
        groupCode: lineItem.GroupCode,
        customerSupportLevel: '1', //TODO: Decide with product team if customerSupportLevel should still be used for automation.
      });
    });
    this.setState({ isAddModalOpen: false, serviceBillingItems: newBillingItems });
  };
  private _handleOnDelete = (billingLineItems) => {
    this.setState({ serviceBillingItems: billingLineItems });
  };

  private _handleSequenceChange = (billingLineItems) => {
    this.setState({ serviceBillingItems: billingLineItems });
  };

  private _handleOnPriceChange = (billingLineItems) => {
    this.setState({ serviceBillingItems: _.clone(billingLineItems) });
  };

  private _onChangeTravelPriceBefore(event) {
    if (event > 0 && event > RECOMMENDED_TRAVEL_PRICE) {
      this.setState({ isWarningTravelPriceBefore: true });
    } else {
      this.setState({ isWarningTravelPriceBefore: false });
    }
  }

  private _onChangeTravelPriceDuring(event) {
    if (event > 0 && event > RECOMMENDED_TRAVEL_PRICE) {
      this.setState({ isWarningTravelPriceDuring: true });
    } else {
      this.setState({ isWarningTravelPriceDuring: false });
    }
  }

  private _changeView = () => {
    this.setState({ isEdit: !this.state.isEdit });
  };

  private _closeDiscardChangesModal = () => {
    this.setState({ isDiscardChangesModalOpen: false });
  };

  private _onSaveServicePricing = async () => {
    const { doUpdateServicePayment, form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        const payload = {
          paymentSourceType: 'NDIS',
          ndisClaims: {
            isChargeTransportBeforeBooking: form.getFieldValue('isChargeTransportBeforeBooking'),
            transportPriceBeforeBooking: form.getFieldValue('transportPriceBeforeBooking'),
            isChargeTransportDuringBooking: form.getFieldValue('isChargeTransportDuringBooking'),
            transportPriceDuringBooking: form.getFieldValue('transportPriceDuringBooking'),
          },
          ndisServiceBillingItems: _.map(this.state.serviceBillingItems, (item) => {
            return { ...item, tax: Number(item.tax), mileagePrice: Number(item.mileagePrice) };
          }),
        };
        await doUpdateServicePayment(payload);
        notification.success({ message: 'Successfully updated service pricing.' });
        this.setState({ isEdit: false });
      } catch (e) {
        notification.error({ message: 'Oops, an error has occurred, please try again.' });
      }
    }
  };

  private _cancelAllChanges = () => {
    this.setState({
      isEdit: false,
      isDiscardChangesModalOpen: false,
      serviceBillingItems: this.props.selectedService.ndisServiceBillingItems
        ? _.cloneDeep(this.props.selectedService.ndisServiceBillingItems)
        : [],
    });
  };

  private _onCloseModal = () => {
    this.setState({
      selectedTab: 'lineItems',
      isWarningTravelPriceBefore: false,
      isWarningTravelPriceDuring: false,
      isEdit: false,
      isDiscardChangesModalOpen: false,
      serviceBillingItems: this.props.selectedService.ndisServiceBillingItems
        ? _.cloneDeep(this.props.selectedService.ndisServiceBillingItems)
        : [],
    });
    this.props.onClose();
  };

  componentDidUpdate(prevProps) {
    const { selectedService } = this.props;

    if (
      selectedService &&
      selectedService.ndisServiceBillingItems !== prevProps.selectedService.ndisServiceBillingItems
    ) {
      this.setState({
        serviceBillingItems: this.props.selectedService.ndisServiceBillingItems
          ? _.cloneDeep(this.props.selectedService.ndisServiceBillingItems)
          : [],
      });
    }
  }

  render() {
    const { selectedService, form } = this.props;
    const { serviceBillingItems, isEdit } = this.state;
    const { getFieldDecorator } = form;
    return (
      <FullScreenScrollableModal isOpen={this.props.isOpen} onClose={this._onCloseModal} width={'large'} canClose>
        <ActionModal
          isOpen={this.state.isDiscardChangesModalOpen}
          onClose={this._closeDiscardChangesModal}
          title={'Discard changes'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>unsaved data</b>, proceeding will discard these changes.
          </Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeDiscardChangesModal}>
              Cancel
            </PrimaryButton>
            <GhostButton size="large" onClick={this._cancelAllChanges}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <AddNDISLineItemsModal
          closeLineItemModal={this._closeLineItemModal}
          isOpen={this.state.isAddModalOpen}
          saveLineItem={this._saveLineItem}
          serviceBillingItems={this.state.serviceBillingItems}
        />
        <div>
          <Title level={2} weight="regular" lineHeight={100} className="mb-x-large">
            NDIS
          </Title>
          <Tabs
            type="card"
            className={'customer-tab'}
            activeKey={this.state.selectedTab}
            onChange={this._setSelectedTab}
          >
            <TabPane key="lineItems" tab="NDIS Line Items" disabled={isEdit}>
              <Row>
                <Col span={24}>
                  <ServDetailsSectionHeader
                    title={'NDIS Line Items'}
                    hasEditButton={this.props.hasEditPermission && !isEdit}
                    editAction={this._changeView}
                    subtitle={'Which NDIS line items are you charging for this service?'}
                  />
                </Col>
              </Row>

              <Row className={'mt-medium'}>
                <NDISLineItemGrid
                  displayMode={isEdit ? 'EDIT' : 'VIEW'}
                  isDeletable={isEdit ? true : false}
                  isSequenceAdjustable={isEdit ? true : false}
                  onDelete={this._handleOnDelete}
                  onSequenceChange={this._handleSequenceChange}
                  isPriceAdjustable={isEdit ? true : false}
                  onPriceChange={this._handleOnPriceChange}
                  lineItems={serviceBillingItems}
                  paymentSourceType="NDIS"
                />
              </Row>
              {isEdit && (
                <Row>
                  <Col span={24}>
                    <GhostButton icon="plus" onClick={this._openLineItemModal}>
                      Add line item(s)
                    </GhostButton>
                    <div className="text-align-right">
                      <GhostButton onClick={() => this.setState({ isDiscardChangesModalOpen: true })}>
                        Discard Changes
                      </GhostButton>

                      <PrimaryButton icon="save" size="large" onClick={this._onSaveServicePricing}>
                        Save
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              )}
            </TabPane>
            {selectedService && selectedService.serviceType !== ServiceType.COORDINATION && (
              <TabPane key="claims" tab="Travel Claims" disabled={isEdit}>
                <Row>
                  <Col span={24}>
                    <ServDetailsSectionHeader
                      title={'Claims'}
                      hasEditButton={this.props.hasEditPermission && !isEdit}
                      editAction={this._changeView}
                      subtitle={'Edit what can be claimed under the NDIS for this service'}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Text className="pt-x-small text-color-secondary">
                      Please indicate if you wish to claim for support worker travel for this service
                    </Text>
                  </Col>
                  <Col span={8}>
                    {isEdit ? (
                      <>
                        <div className={'mt-medium'}>
                          {getFieldDecorator('isChargeTransportBeforeBooking', {
                            valuePropName: 'checked',
                            initialValue:
                              selectedService.serviceClaimConfig &&
                              selectedService.serviceClaimConfig.isChargeNdisTransportBeforeBooking
                                ? selectedService.serviceClaimConfig.isChargeNdisTransportBeforeBooking
                                : false,
                          })(
                            <Checkbox>
                              Claim for worker travel <b>to/from</b> a booking.
                            </Checkbox>,
                          )}
                          <br />
                          {form.getFieldValue('isChargeTransportBeforeBooking') && (
                            <Form.Item className={'m-none ml-large mt-small'}>
                              <div>
                                {getFieldDecorator('transportPriceBeforeBooking', {
                                  initialValue:
                                    selectedService.serviceClaimConfig &&
                                    selectedService.serviceClaimConfig.ndisClaims.transportPriceBeforeBooking
                                      ? selectedService.serviceClaimConfig.ndisClaims.transportPriceBeforeBooking
                                      : null,
                                  rules: [
                                    {
                                      required: true,
                                      message: 'Please enter a price.',
                                    },
                                  ],
                                })(
                                  <NumberInput
                                    min={0}
                                    step={0.05}
                                    max={9999}
                                    onChange={(event) => this._onChangeTravelPriceBefore(event)}
                                    className={'ml-x-small'}
                                    addonBefore={'$'}
                                    style={{ width: '120px' }}
                                  />,
                                )}{' '}
                                price per km.
                                {this.state.isWarningTravelPriceBefore && (
                                  <div
                                    className={'text-color-orange text-size-regular'}
                                    style={{ lineHeight: 'initial' }}
                                  >
                                    The NDIS <b>recommends</b> that cost per km do not exceed $
                                    {RECOMMENDED_TRAVEL_PRICE} for vehicles not modified for accessibility.
                                  </div>
                                )}
                              </div>
                            </Form.Item>
                          )}
                        </div>

                        <div className={'mt-medium mb-large'}>
                          {getFieldDecorator('isChargeTransportDuringBooking', {
                            valuePropName: 'checked',
                            initialValue:
                              selectedService.serviceClaimConfig &&
                              selectedService.serviceClaimConfig.isChargeNdisTransportDuringBooking
                                ? selectedService.serviceClaimConfig.isChargeNdisTransportDuringBooking
                                : false,
                          })(
                            <Checkbox>
                              Claim for worker travel <b>during</b> a booking.
                            </Checkbox>,
                          )}
                          <br />
                          {form.getFieldValue('isChargeTransportDuringBooking') && (
                            <Form.Item className={'m-none ml-large mt-small'}>
                              <div>
                                {getFieldDecorator('transportPriceDuringBooking', {
                                  initialValue:
                                    selectedService.serviceClaimConfig &&
                                    selectedService.serviceClaimConfig.ndisClaims.transportPriceDuringBooking
                                      ? selectedService.serviceClaimConfig.ndisClaims.transportPriceDuringBooking
                                      : null,
                                  rules: [
                                    {
                                      required: true,
                                      message: 'Please enter a price.',
                                    },
                                  ],
                                })(
                                  <NumberInput
                                    min={0}
                                    step={0.05}
                                    max={9999}
                                    onChange={(event) => this._onChangeTravelPriceDuring(event)}
                                    className={'ml-x-small'}
                                    addonBefore={'$'}
                                    style={{ width: '120px' }}
                                  />,
                                )}{' '}
                                price per km.
                                <br />
                                {this.state.isWarningTravelPriceDuring && (
                                  <div
                                    className={'text-color-orange text-size-regular'}
                                    style={{ lineHeight: 'initial' }}
                                  >
                                    The NDIS <b>recommends</b> that cost per km do not exceed $
                                    {RECOMMENDED_TRAVEL_PRICE} for vehicles not modified for accessibility.
                                  </div>
                                )}
                              </div>
                            </Form.Item>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          {!selectedService.serviceClaimConfig.isChargeNdisTransportBeforeBooking ? (
                            <Text>
                              <b>To/from</b>: Travel to/from a booking{' '}
                              <Text weight={'bold'} color="red-dark">
                                cannot
                              </Text>{' '}
                              be claimed for this service.
                            </Text>
                          ) : (
                            <Text>
                              <b>To/from</b>: Travel to/from a booking <Text weight={'bold'}>can</Text> be claimed for
                              this service.
                              <br />
                              <b>
                                {CommonUtils.formatPrice(
                                  selectedService.serviceClaimConfig.ndisClaims.transportPriceBeforeBooking,
                                )}
                              </b>{' '}
                              per km.
                            </Text>
                          )}
                          <br />
                          <br />
                          {!selectedService.serviceClaimConfig.isChargeNdisTransportDuringBooking ? (
                            <Text>
                              <b>During</b>: Travel during a booking{' '}
                              <Text weight={'bold'} color="red-dark">
                                cannot
                              </Text>{' '}
                              be claimed for this service.
                            </Text>
                          ) : (
                            <Text>
                              <b>During</b>: Travel during a booking <Text weight={'bold'}>can</Text> be claimed for
                              this service.
                              <br />
                              <b>
                                {CommonUtils.formatPrice(
                                  selectedService.serviceClaimConfig.ndisClaims.transportPriceDuringBooking,
                                )}
                              </b>{' '}
                              per km.
                            </Text>
                          )}
                        </div>
                      </>
                    )}
                  </Col>
                  <Col span={8} />
                </Row>
                <Row>
                  {isEdit && (
                    <Row>
                      <Col span={24}>
                        <div className="text-align-right">
                          <GhostButton onClick={() => this.setState({ isDiscardChangesModalOpen: true })}>
                            Discard Changes
                          </GhostButton>

                          <PrimaryButton icon="save" size="large" onClick={this._onSaveServicePricing}>
                            Save
                          </PrimaryButton>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Row>
              </TabPane>
            )}
          </Tabs>
        </div>
      </FullScreenScrollableModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedService: state.servicesStore.selectedService,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateServicePayment: dispatch.servicesStore.doUpdateServicePayment,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<IServiceNDISLineItemsModalProps>()(ServiceNDISLineItemsModal));
