import { reactTrpc } from 'utilities/react-trpc';
import React from 'react';
import { Anchor, Alert } from '@good/ui/core';
import { daysBetween } from 'utilities/date-utils';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';
import PermissionUtils from 'utilities/permission-utils';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const SubscriptionStatusBanner = () => {
  const flags = useFlags();
  const portalUser = usePortalUser();
  const hasSubscriptionBillingPermission = PermissionUtils.validatePermission(
    'ViewSubscriptionBilling',
    portalUser.permissions.permissionRoles,
  );

  const { data: subscriptionPaymentDetails } = reactTrpc.serviceProvider.subscription.paymentStatus.useQuery();
  if (!subscriptionPaymentDetails) return null;
  const isPastDueOrUnpaid =
    subscriptionPaymentDetails.subscriptionStatus === 'past_due' ||
    subscriptionPaymentDetails.subscriptionStatus === 'unpaid';

  const daysPastDue = Math.floor(
    daysBetween(
      new Date(
        (subscriptionPaymentDetails.latestInvoice?.due_date ??
          subscriptionPaymentDetails.latestInvoice?.created ??
          Date.now() / 1000) * 1000,
      ),
      new Date(),
    ),
  );

  if (!flags['newSubscriptionManagement']) return null;
  return (
    <>
      {hasSubscriptionBillingPermission && isPastDueOrUnpaid && daysPastDue < 7 ? (
        <PaymentFailedWarning />
      ) : hasSubscriptionBillingPermission && isPastDueOrUnpaid && daysPastDue >= 7 && daysPastDue < 14 ? (
        <SevenDaysOverdueWarning daysPastDue={daysPastDue} />
      ) : isPastDueOrUnpaid && daysPastDue >= 14 && daysPastDue < 21 ? (
        <FourteenDaysUnpaidWarning daysPastDue={daysPastDue} />
      ) : isPastDueOrUnpaid && daysPastDue >= 21 && daysPastDue < 30 ? (
        <TwentyOneDaysUnpaidWarning daysPastDue={daysPastDue} />
      ) : isPastDueOrUnpaid && daysPastDue >= 30 ? (
        <OverThirtyDaysUnpaidWarning />
      ) : subscriptionPaymentDetails.subscriptionStatus === 'canceled' ? (
        <SubscriptionCanceledError />
      ) : null}
    </>
  );
};

const PaymentFailedWarning = () => (
  <Alert color='yellow' radius={0} px='lg' py='xs' title='Payment failed.'>
    Your payment has failed. Please rectify the issue immediately to avoid the suspension of your account. If you need
    help please contact our customer support team via our{' '}
    <Anchor size='sm' href='/support'>
      help center
    </Anchor>
    , or at{' '}
    <Anchor size='sm' href='mailto:support@goodhuman.me'>
      support@goodhuman.me
    </Anchor>
    .
  </Alert>
);

const SevenDaysOverdueWarning = ({ daysPastDue }: { daysPastDue: number }) => (
  <Alert color='yellow' radius={0} px='lg' py='xs' title='Payment overdue.'>
    Your payment is {daysPastDue} days overdue. Please rectify the issue immediately to avoid the suspension of your
    account. If you need help please contact our customer support team via our{' '}
    <Anchor size='sm' href='/support'>
      help center
    </Anchor>
    , or at{' '}
    <Anchor size='sm' href='mailto:support@goodhuman.me'>
      support@goodhuman.me
    </Anchor>
    .
  </Alert>
);

const FourteenDaysUnpaidWarning = ({ daysPastDue }: { daysPastDue: number }) => (
  <Alert color='red' radius={0} px='lg' py='xs' title='Your subscription is unpaid.'>
    Your payment is {daysPastDue} days in arrears. Please rectify the issue immediately to avoid the suspension of this
    account. If you need help please contact our customer support team via our{' '}
    <Anchor size='sm' href='/support'>
      help center
    </Anchor>{' '}
    or at{' '}
    <Anchor size='sm' href='mailto:support@goodhuman.me'>
      support@goodhuman.me
    </Anchor>
    .
  </Alert>
);

const TwentyOneDaysUnpaidWarning = ({ daysPastDue }: { daysPastDue: number }) => (
  <Alert color='red' radius={0} px='lg' py='xs' title='Your subscription is unpaid.'>
    We will be removing access for all users of the system in {30 - daysPastDue} days if payment isn&apos;t received
    prior. If you need help please contact our customer support team via our{' '}
    <Anchor size='sm' href='/support'>
      help center
    </Anchor>{' '}
    or at{' '}
    <Anchor size='sm' href='mailto:support@goodhuman.me'>
      support@goodhuman.me
    </Anchor>
    .
  </Alert>
);

const OverThirtyDaysUnpaidWarning = () => (
  <Alert color='red' radius={0} px='lg' py='xs' title='Your subscription is unpaid.'>
    Your account is scheduled to be suspended due to a failed payment. If you need help please contact our customer
    support team via our{' '}
    <Anchor size='sm' href='/support'>
      help center
    </Anchor>{' '}
    or at{' '}
    <Anchor size='sm' href='mailto:support@goodhuman.me'>
      support@goodhuman.me
    </Anchor>
    .
  </Alert>
);

const SubscriptionCanceledError = () => (
  <Alert color='red' radius={0} px='lg' py='xs' title='Account suspended.'>
    We&apos;re so sorry. Your account has been suspended due to a failed payment. Don&apos;t fret, you haven&apos;t lost
    your data and can still access your account once payment is made. Please update your payment details to ensure
    payment can be made. If you are unable to make payment or need assistance please contact our customer support team
    at{' '}
    <Anchor size='sm' href='mailto:support@goodhuman.me'>
      support@goodhuman.me
    </Anchor>{' '}
    . We&apos;re here to help in any way we can.
  </Alert>
);
